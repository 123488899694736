import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@hooks/useAppSelector";
import { getProfileSelector } from "@store/selectors";
import { Routes } from "@enums/routes";
import { useEffect } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getProfile } from "@store/slices";
import styles from "./CartLayout.module.scss";

export const TotalBlock = ({ state, sum, btnNone }: any) => {
  const profile = useAppSelector(getProfileSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const at = localStorage.getItem("at");

  useEffect(() => {
    if (at) {
      dispatch(getProfile());
    }
  }, [at]);

  return (
    <div className={styles.rightBlock}>
      <div>
        <div className={styles.priceBlock}>
          <div className={styles.priceRow}>
            <div className={styles.total}>{t("cart.cost")}</div>
            <div className={styles.res}>
              {state.length
                ? `${state[0].current_price} ${t("som")}`
                : `0 ${t("som")}`}
            </div>
          </div>
          <div className={styles.priceRow}>
            <div className={styles.total}>{t("cart.name")}</div>
            <div className={styles.res}>
              {state.length ? state[0].name : t("cart.name")}
            </div>
          </div>
          <div className={styles.priceRow}>
            <div className={styles.total}>{t("cart.total")}</div>
            <div className={styles.price}>
              {sum} {t("som")}
            </div>
          </div>
        </div>
        {btnNone && (
          <Button
            className={styles.button}
            onClick={() =>
              navigate(profile.email ? Routes.PERSONAL_DATA : Routes.LOGIN)
            }
            disabled={
              state.length
                ? state.some(
                    (item: any) => item.buyQuantity > item.sizeQuantity
                  )
                : true
            }
          >
            {t("cart.button")}
          </Button>
        )}
      </div>
    </div>
  );
};
