import { Select } from "antd";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import i18n from "../../../i18n";
import styles from "./LanguageSelector.module.scss";

const { Option } = Select;

const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
  LocalStorage.setItem(LocalStorageKey.Lang, language);
  window.location.reload();
};

export const LanguageSelectorBurger = () => {
  const lang = LocalStorage.getItem(LocalStorageKey.Lang);

  return (
    <Select
      defaultValue={lang ?? "ru"}
      className={styles.select}
      onChange={changeLanguage}
      style={{ width: "100%" }}
    >
      <Option value="ky">Кыргызча</Option>
      <Option value="ru">Русский</Option>
      <Option value="en">English</Option>
    </Select>
  );
};
