import { useEffect, useState } from "react";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Image, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@hooks/useAppDispatch";
import {
  getClotheLoadingByIdSelector,
  getClothesByIdSelector,
  getClothesSelector,
} from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { getClothes, getProductsById, setCount } from "@store/slices";
import { IClothes } from "@enums/slices";
import { ProductCard } from "@ui-kit/productCard";
import { SEO } from "@utils/SEO";
import { RoutePath } from "@router/routePath";
import { useNavigate } from "react-router-dom";
import styles from "./DetailsLayout.module.scss";

const { Option } = Select;

export const DetailsLayout = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clothes = useAppSelector(getClothesByIdSelector);
  const keys = Object.keys(clothes?.sizes || "");
  const [keysArr, setKeysArr] = useState([]);
  const [size, setSize] = useState<any>("");
  const recommendClothes = useAppSelector(getClothesSelector);
  const searchString = new URLSearchParams(window.location.search);
  const paramsId = searchString.get("id") || "";
  const [status, setStatus] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [newArray, setNewArray]: any[] = useState([]);
  const navigate = useNavigate();
  let sliceCount: number;
  if (screenWidth < 900) {
    sliceCount = 1;
  } else if (screenWidth < 1350) {
    sliceCount = 2;
  } else {
    sliceCount = 3;
  }

  useEffect(() => {
    const arr: any = [];
    if (clothes?.sizes) {
      keys.map((item: any) => {
        if (Number(clothes.sizes[item]) > 0) {
          arr.push(item);
        }
        return null;
      });
      setKeysArr(arr);
    }
  }, [clothes]);

  useEffect(() => {
    dispatch(getProductsById({ id: paramsId }));
    dispatch(getClothes({ params: "?sort=desc" }));
  }, [paramsId]);

  useEffect(() => {
    if (!paramsId && recommendClothes.length) {
      navigate(`${RoutePath.details}?id=${recommendClothes[0].id}`);
    }
  }, [paramsId, recommendClothes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [clothes]);

  /* eslint-disable no-plusplus */

  const getRandomElements = (array: any[], num: number) => {
    if (num > array.length) {
      return array;
    }

    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray.slice(0, num);
  };

  useEffect(() => {
    if (recommendClothes.length) {
      const array: any[] = getRandomElements(recommendClothes, sliceCount);
      setNewArray(array);
    }
  }, [recommendClothes, sliceCount]);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      setSize("");
    };
  }, []);

  // useEffect(() => {
  //   if (keys[0] && !size) {
  //     setSize(keys[0]);
  //   }
  // }, [keys]);

  const iconStatus = () => {
    const res = JSON.parse(localStorage.getItem("cart") || "[]");
    dispatch(setCount(res.length));
    const isProductInCart = res.some(
      (item: any) => item.id === clothes?.id && item.size === size
    );
    setStatus(isProductInCart);
  };

  const addToCart = (product: IClothes) => {
    const cart = JSON.parse(localStorage.getItem("cart") || "[]");
    const existingProduct = cart.find(
      (item: IClothes) => item.id === product.id && item.size === size
    );

    if (existingProduct) {
      const updatedCart = cart.filter(
        (item: IClothes) => !(item.id === product.id && item.size === size)
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } else {
      cart.push({
        ...product,
        size,
        buyQuantity: 1,
        sizeQuantity: size ? clothes?.sizes[size] : product.quantity,
      });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    iconStatus();
  };

  useEffect(() => {
    iconStatus();
  }, [clothes, size]);

  return (
    <div className={styles.pageBlock}>
      <SEO
        title={t("product")}
        url={`${RoutePath.details}?id=${
          recommendClothes.length && recommendClothes[0].id
        }`}
      />

      <div className={styles.mainBlock}>
        <div className={styles.contentBlock}>
          <div className={styles.imgBlock}>
            <div className={styles.bigImgBlock}>
              <Image src={clothes?.images[0]} alt="" className={styles.img} />
            </div>
            <div className={styles.smallImgBlock}>
              {clothes?.images[1] && (
                <Image
                  src={clothes?.images[1]}
                  alt=""
                  className={styles.smallImg}
                />
              )}
              {clothes?.images[2] && (
                <Image
                  src={clothes?.images[2]}
                  alt=""
                  className={styles.smallImg}
                />
              )}
              {clothes?.images[3] && (
                <Image
                  src={clothes?.images[3]}
                  alt=""
                  className={styles.smallImg}
                />
              )}
            </div>
          </div>
          <div className={styles.descBlock}>
            <div className={styles.name}>{clothes?.name}</div>
            <div className={styles.details}>{t("details.title")}</div>
            <div className={styles.desc}>{clothes?.description}</div>
            <div className={styles.selectBlock}>
              {clothes?.sizes && (
                <Select
                  value={size || "def"}
                  defaultValue="def"
                  placeholder={t("choose")}
                  style={{ width: 180 }}
                  onChange={(values) => setSize(values)}
                >
                  <Option value="def" disabled>
                    {t("choose")}
                  </Option>
                  {clothes?.sizes && keysArr.length ? (
                    keysArr.map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))
                  ) : (
                    <div />
                  )}
                </Select>
              )}
            </div>
            <div className={styles.priceBlock}>
              <div className={styles.desc}>{t("details.total")}</div>
              <div className={styles.price}>
                {clothes?.current_price} {t("som")}
              </div>
            </div>
            <div className={styles.oldPriceBlock}>
              {clothes?.old_price ? (
                <div className={styles.oldPrice}>
                  {clothes?.old_price} {t("som")}
                </div>
              ) : (
                ""
              )}
            </div>
            {status ? (
              <Button
                className={styles.button}
                onClick={() => clothes && addToCart(clothes)}
              >
                <ShoppingCartOutlined />
                {t("details.delete")}
              </Button>
            ) : (
              <Button
                className={styles.button}
                onClick={() => clothes && addToCart(clothes)}
                disabled={clothes?.sizes !== null && !size}
              >
                <ShoppingCartOutlined />
                {t("details.button")}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.recommend}>
          <div className={styles.recContent}>
            <div className={styles.title2}>{t("category.res")}</div>
            <div className={styles.cardList}>
              {newArray.length ? (
                newArray.map((item: any) => (
                  <ProductCard data={item} key={item.id} details />
                ))
              ) : (
                <div className={styles.tovar}>{t("details.none")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
