import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { FormInput } from "@ui-kit/formInput";
import { validatePassword } from "@validates/codeValidates";
import { useConfirmPassword } from "@features/auth/hooks";

export const Forms: React.FC = () => {
  const { t } = useTranslation();
  const { password } = useConfirmPassword();

  const compareToFirstPassword = (_: any, value: string) => {
    if (value && value !== password) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject("Пароли не совпадают");
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите пароль",
          },
          {
            validator: validatePassword(t),
          },
        ]}
      >
        <FormInput
          allowClear
          type="password"
          placeholder={t("login.password")}
        />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: "Пожалуйста, подтвердите пароль",
          },
          {
            validator: compareToFirstPassword,
          },
        ]}
      >
        <FormInput
          allowClear
          type="password"
          placeholder={t("register.confirmPassword")}
        />
      </Form.Item>
    </>
  );
};
