import React from "react";
import { SignInType } from "@models/login";
import { Form } from "antd";
import { Trans } from "react-i18next";
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { SignInFacebook, signInGoogle } from "@store/slices";
import { useNavigate } from "react-router-dom";
import { Links } from "@enums/links";
import { IconButton } from "@mui/material";
import { RoutePath } from "@router/routePath";
import { useAppSelector } from "@hooks/useAppSelector";
import { getLoadingSelector } from "@store/selectors";
import { YandexAuth } from "./YandexAuth";
import { SubmitButton } from "../components";
import { useLogin } from "../hooks";
import { FormLayout } from "../layout";
import { Forms } from "./forms";
import { ForgotPassword } from "./forgotPassword";
import styles from "./Login.module.scss";

export const Login: React.FC = () => {
  const { form, onFinish, onFinishFailed } = useLogin();
  const loading = useAppSelector(getLoadingSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const responseSocial = ({ provider, data }: IResolveParams) => {
    if (!data) return;
    if (provider === "google") {
      const res = {
        token: data.access_token,
        type: data.token_type,
      };
      dispatch(signInGoogle({ values: res, navigate }));
    }
    if (provider === "facebook") {
      const res = {
        token: data.accessToken,
      };
      dispatch(SignInFacebook({ values: res, navigate }));
    }
  };

  return (
    <FormLayout>
      <Form<SignInType>
        onFinish={onFinish}
        autoComplete="off"
        className={styles.form}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Forms />
        <SubmitButton loading={loading} form={form}>
          <Trans i18nKey="login.submit" />
        </SubmitButton>
        <div className={styles.oauthLogin}>
          <LoginSocialGoogle
            client_id={process.env.REACT_APP_CLIENT_ID || ""}
            scope="email profile"
            isOnlyGetToken
            typeResponse="accessToken"
            onResolve={(response: IResolveParams) => responseSocial(response)}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <div className={styles.authBlock}>
              <img className={styles.authIcon} src={Links.google} alt="" />
            </div>
          </LoginSocialGoogle>
          <YandexAuth />
          <LoginSocialFacebook
            appId={process.env.REACT_APP_CLIENT_FACEBOOK_ID || ""}
            isOnlyGetToken
            fieldsProfile="id,first_name,last_name,picture,email"
            onResolve={(response: IResolveParams) => responseSocial(response)}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <div className={styles.authBlock}>
              <img
                className={styles.authIcon}
                src={Links.facebookAuth}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          </LoginSocialFacebook>
          <IconButton
            className={styles.logoutBlock}
            onClick={() => navigate(RoutePath.main)}
          >
            <div className={styles.authBlock}>
              <img className={styles.authIcon} src={Links.logoutAuth} alt="" />
            </div>
          </IconButton>
        </div>
        <ForgotPassword />
      </Form>
    </FormLayout>
  );
};
