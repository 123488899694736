import { useEffect, useState } from "react";
import { IClothes } from "@enums/slices";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "@router/routePath";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { setCount } from "@store/slices";
import styles from "./ProductCard.module.scss";

type PropsType = {
  data: IClothes;
  details?: boolean;
};

export const ProductCard: React.FC<PropsType> = ({ data, details }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const dispatch = useAppDispatch();

  const iconStatus = () => {
    const res = JSON.parse(localStorage.getItem("cart") || "[]");
    dispatch(setCount(res.length));
    const isProductInCart = res.some((item: any) => item.id === data.id);
    setStatus(isProductInCart);
  };

  useEffect(() => {
    iconStatus();
  }, []);

  const navigateDetails = () => {
    navigate(`${RoutePath.details}?id=${data.id}`);
    window.location.reload();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`${details ? styles.details : styles.card}`}
      role="button"
      tabIndex={0}
      onClick={() =>
        details
          ? navigateDetails()
          : navigate(`${RoutePath.details}?id=${data.id}`)
      }
    >
      <img
        src={data.images[0]}
        alt=""
        className={`${details ? styles.detailsImg : styles.img}`}
      />
      <div className={styles.content}>
        <div className={styles.name}>{data.name}</div>
        <div className={styles.footer}>
          <div className={styles.name}>{`${data.current_price} ${t(
            "som"
          )}`}</div>
          {/* <Button
            onClick={() =>
              details
                ? navigateDetails()
                : navigate(`${RoutePath.details}?id=${data.id}`)
            }
            className={styles.button}
          >
            {t("clothes.button")}
          </Button> */}
        </div>
      </div>
    </div>
  );
};
