import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { codeValidates } from "@validates/codeValidates";
import { FormMaskedInput } from "@ui-kit/FormMaskedInput";

export const CodeForm = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        name="code"
        rules={[
          {
            required: true,
            message: t("register.firstMessage"),
          },
          {
            pattern: codeValidates,
            message: t("register.secondMessage"),
          },
        ]}
      >
        <FormMaskedInput placeholder="Code" />
      </Form.Item>
    </div>
  );
};
