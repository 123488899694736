export enum AppRoutes {
  MAIN = "main",
  LOGIN = "login",
  REGISTER = "register",
  FORGOT_PASSWORD = "forgot-password",
  CONFIRM_PASSWORD = "forget-password",
  CATEGORIES = "categories",
  COLLECTIONS = "collections",
  DETAILS = "details",
  CART = "cart",
  PERSONAL_DATA = "personal-data",
  DELIVERY = "delivery",
  PROFILE = "profile",
  ORDER = "order",
  FAQ = "asked-questions",
  CONTACT = "contact",
  PRIVACY = "privacy-policy",
}

export enum Routes {
  MAIN = "/",
  ORDER = "/order",
  CART = "/cart",
  LOGIN = "/login",
  REGISTER = "/register",
  CATEGORIES = "/categories",
  COLLECTIONS = "/collections",
  DETAILS = "/details",
  PERSONAL_DATA = "/personal-data",
  DELIVERY = "/delivery",
  FORGOT_PASSWORD = "/forgot-password",
  CONFIRM_PASSWORD = "/forget-password",
  PROFILE = "/profile",
  FAQ = "/asked-questions",
  CONTACT = "/contact",
  PRIVACY = "/privacy-policy",
}

export enum Regions {
  BISHKEK = "bishkek",
  JALAL_ABAD = "jalal-abad",
}
