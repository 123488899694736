import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ForgotPassword } from "@features/auth";
import { AuthFormLayout } from "@ui-kit/layouts";
import styles from "./ForgotPasswordLayout.module.scss";

export const ForgotPasswordLayout = () => {
  const { t } = useTranslation();
  return (
    <AuthFormLayout>
      <Typography.Title className={styles.loginTitle}>
        {t("forgotPassword.title")}
      </Typography.Title>
      <ForgotPassword />
    </AuthFormLayout>
  );
};
