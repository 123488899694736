import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Register } from "@features/auth";
import { AuthFormLayout } from "@ui-kit/layouts";
import { SEO } from "@utils/SEO";
import styles from "./RegisterLayout.module.scss";

export const RegisterLayout = () => {
  const { t } = useTranslation();
  return (
    <AuthFormLayout>
      <SEO title={t("register.title")} url="/register" />

      <Typography.Title className={styles.loginTitle}>
        {t("register.title")}
      </Typography.Title>
      <Register />
    </AuthFormLayout>
  );
};
