import { createSelector } from "@reduxjs/toolkit";
import { BarcaState } from "@store/slices";

type WithbarcaState = {
  barca: BarcaState;
};

export const barcaStateSelector = (state: WithbarcaState): BarcaState =>
  state.barca;

export const getEmailSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.email;
  }
);

export const getOrderslSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.orders;
  }
);

export const getVisiblelSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.visible;
  }
);

export const getKeywordslSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.keywords;
  }
);

export const getPersonalVisibleSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.personalVisible;
  }
);

export const getPasswordSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.password;
  }
);

export const getPersonalSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.personal;
  }
);

export const getCollectionsSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.collections;
  }
);

export const getContactSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.contact;
  }
);

export const getInfoPageSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.infoPage;
  }
);

export const getProfileSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.profile;
  }
);

export const getEmailConfirmSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.emailConfirm;
  }
);

export const getCategoriesSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.categories;
  }
);

export const getLoadingSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.loading;
  }
);

export const getClothesSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.clothes;
  }
);

export const getClothesAmountSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.clotheAmount;
  }
);

export const getClothesByIdSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.clothe;
  }
);

export const getClotheLoadingByIdSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.clotheLoading;
  }
);

export const getCountSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.cartCount;
  }
);

export const getNetworksSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.networks;
  }
);
