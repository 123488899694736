import { LocalStorageKey } from "@enums/localStorage";
import { LocalStorage } from "@localStorage/localStorage";
import axios, {
  AxiosError,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios";

interface CustomAxiosRequestConfig extends CreateAxiosDefaults {
  retry?: boolean;
}

interface CustomInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  retry?: boolean;
}

const $api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  retry: false,
} as CustomAxiosRequestConfig);

const refreshTokens = async (): Promise<string | null> => {
  try {
    const response = await $api.get("/user/auth/refresh");
    const newToken = response.data.accessToken;
    LocalStorage.setItem(LocalStorageKey.AccessToken, newToken);
    return newToken;
  } catch (error) {
    console.error("Refresh token error:", error);
    return null;
  }
};

$api.interceptors.request.use(
  (config: CustomInternalAxiosRequestConfig) => {
    const token = LocalStorage.getItem(LocalStorageKey.AccessToken);
    const lang = LocalStorage.getItem(LocalStorageKey.Lang);

    if (token) {
      config.headers.set("Authorization", `Bearer ${token}`);
    }
    config.headers.set("Accept-Language", lang ?? "ru");

    return config;
  },
  (error) => Promise.reject(error)
);

$api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const { config, response } = error;
    const request = config as CustomInternalAxiosRequestConfig;

    if (
      response?.status === 401 &&
      !request.retry &&
      !request.url?.endsWith("refresh")
    ) {
      request.retry = true;
      try {
        const newToken = await refreshTokens();
        if (newToken) {
          request.headers.set("Authorization", `Bearer ${newToken}`);
          request.retry = true;

          return $api(request);
        }
      } catch (refreshError) {
        console.error("Error during token refresh:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default $api;
