import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Fragment, useEffect } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getContact } from "@store/slices";
import { useAppSelector } from "@hooks/useAppSelector";
import { getContactSelector } from "@store/selectors";
import { Loader } from "@ui-kit/loader";
import { Empty } from "antd";
import { SEO } from "@utils/SEO";
import { Routes } from "@enums/routes";
import styles from "./Contact.module.scss";

export const ContactLayout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { items, loading } = useAppSelector(getContactSelector);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getContact({ type: "store" }));
  }, []);

  return (
    <div className={styles.container}>
      <SEO
        titleNew={t("contact.title")}
        description={t("contact.title.subTitle")}
        url={Routes.CONTACT}
      />
      <div className={styles.contact}>
        <div className={styles.header}>
          <h1
            className={cn(styles.title, {
              [styles.ru]: i18n.language === "ru",
            })}
          >
            {t("contact.title")}
          </h1>
          <div className={styles.subTitle} />
        </div>
        <div className={styles.content}>
          {loading && <Loader dark />}
          {!loading && !items.length ? (
            <Empty description={t("empty")} style={{ margin: "0 auto" }} />
          ) : (
            items.map((contact) => (
              <div
                key={contact.id}
                className={cn(styles.info, {
                  [styles.ru]: i18n.language === "ru",
                })}
              >
                <h2 className={styles.heading}>{contact.title}</h2>
                <p className={styles.address}>{contact.adreses}</p>
                <p>
                  {contact.phone.map((phone) => (
                    <>
                      <strong>{t("contact.number")}</strong> {phone.phoneNumber}
                      <br />
                    </>
                  ))}
                  {contact.fax && (
                    <>
                      <strong>{t("contact.fax")}</strong> {contact.fax} <br />
                    </>
                  )}
                  {contact.email && (
                    <>
                      <strong>{t("contact.email")}</strong>{" "}
                      <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      <br />
                    </>
                  )}
                  {contact.site && (
                    <>
                      <strong>Web:</strong>{" "}
                      <a
                        href={contact.site.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {contact.site.title}
                      </a>
                    </>
                  )}
                </p>
                <p>
                  {contact.workingHours.map((workTime) => (
                    <Fragment key={workTime.id}>
                      <strong>{workTime.label}</strong>: {workTime.text}
                      <br />
                    </Fragment>
                  ))}
                </p>
                <p>{contact.note}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
