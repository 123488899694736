import { createContext, useMemo } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export const TooltipContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  success: (is: string) => {},
});

export const TooltipProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  const success = (messageStr: string) => {
    messageApi.open({
      type: "success",
      content: messageStr,
    });
  };

  return useMemo(
    () => (
      <TooltipContext.Provider
        value={{
          success,
        }}
      >
        {contextHolder}
        {children}
      </TooltipContext.Provider>
    ),
    []
  );
};
