import React, { useState } from "react";
import { Button, Form, FormInstance, Row } from "antd";
import { useTranslation } from "react-i18next";
import { FormInput, FormPassword } from "@ui-kit/formInput";
import { useRegister, useShowCodeModal } from "@features/auth/hooks";
import { getEmailConfirmSelector } from "@store/selectors";
import { validatePassword } from "@validates/codeValidates";
import { useAppSelector } from "@hooks/useAppSelector";
import styles from "./Forms.module.scss";

type PropsType = {
  form: FormInstance;
};

export const Forms = ({ form }: PropsType) => {
  const { t } = useTranslation();
  const { onOpen, email } = useShowCodeModal();
  const { password } = useRegister();
  const emailConfirm = useAppSelector(getEmailConfirmSelector);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const compareToFirstPassword = (_: any, value: string) => {
    if (value && value !== password) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(t("validateSms.passwordMatch"));
    }
    return Promise.resolve();
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await form.validateFields(["email"]);
      setIsEmailValid(true);
    } catch (error) {
      setIsEmailValid(false);
    }
  };

  return (
    <>
      <Form.Item name="name">
        <FormInput allowClear placeholder={t("register.name")} />
      </Form.Item>

      <Form.Item name="lastname">
        <FormInput allowClear placeholder={t("register.username")} />
      </Form.Item>

      <Form.Item
        name="email"
        className={`${email && styles.emailForm} ${
          email && emailConfirm && styles.notEmail
        }`}
        rules={[
          {
            required: true,
            message: t("validateSms.emailReq"),
          },
          {
            type: "email",
            message: t("validateSms.emailCorrect"),
          },
        ]}
      >
        <FormInput
          allowClear
          placeholder={t("login.email")}
          onChange={handleEmailChange}
        />
      </Form.Item>

      {email && !emailConfirm && isEmailValid && (
        <Row className={styles.btnRow}>
          <Button onClick={onOpen} className={styles.btn}>
            {t("register.confirmEmail")}
          </Button>
        </Row>
      )}

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t("validateSms.passwordReq"),
          },
          {
            validator: validatePassword(t),
          },
        ]}
      >
        <FormPassword type="password" placeholder={t("login.password")} />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: t("validateSms.passwordConfirm"),
          },
          {
            validator: compareToFirstPassword,
          },
        ]}
      >
        <FormPassword
          type="password"
          placeholder={t("register.confirmPassword")}
        />
      </Form.Item>
    </>
  );
};
