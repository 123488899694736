/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button, Input, Layout } from "antd";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AnimatePresence, motion } from "framer-motion";
import { AppRoutes, Routes } from "@enums/routes";
import { Links } from "@enums/links";
import cn from "classnames";
import { IMenuItem } from "@utils/getLink";
import {
  getCollections,
  getKeywords,
  getProducts,
  getProductsById,
  getProfile,
  setCount,
  setVisible,
} from "@store/slices";
import {
  getCategoriesSelector,
  getCollectionsSelector,
  getCountSelector,
  getKeywordslSelector,
  getProfileSelector,
  getVisiblelSelector,
} from "@store/selectors";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { RoutePath } from "@router/routePath";
import {
  CaretRightOutlined,
  CloseOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { LanguageSelectorBurger } from "@ui-kit/selectors";
import { ClothesMight } from "@features/main/category/ClothesList";
import { ProductCardCustom } from "@ui-kit/productCard2";
import { Loader } from "@ui-kit/loader";
import { HeaderCard } from "@ui-kit/collectionCard/HeaderCard";
import { useDebounce } from "@hooks/useDebounce";
import { HeaderMenu } from "./components";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { LoginHeader } from "./components/loginHeader";
import { CollectionBlock } from "./components/academyInnerBlock/CollectionBlock";
import { CategoryBlock } from "./components/academyInnerBlock/CategoryBlock";
import { LoginHeaderMobile } from "./components/loginHeaderMobile";
import styles from "./Header.module.scss";

interface BurgerMenuProps {
  toggleMenu: () => void;
  closeMenuAndNavigate: (path: string) => void;
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({
  toggleMenu,
  closeMenuAndNavigate,
}) => {
  const { t } = useTranslation();
  const [showAcademyBlock, setShowAcademyBlock] = useState(false);
  const [showServiceBlock, setShowServiceBlock] = useState(false);
  const profile = useAppSelector(getProfileSelector);

  const menuItems = [
    { label: t("header.main"), path: "/", transitionDuration: 0.2 },
    { label: t("header.cart"), path: "/cart", transitionDuration: 0.4 },
  ];
  const handleServiceClick = () => {
    setShowServiceBlock((prev) => !prev);
  };
  const handleAcademyClick = () => {
    setShowAcademyBlock((prev: boolean) => !prev);
  };

  return (
    <motion.div
      className={styles.background}
      animate={{
        x: 0,
        backgroundColor: "#181733",
      }}
      exit={{
        x: "100%",
        transition: { duration: 0.2 },
      }}
      initial={{ x: "100%" }} // Starts from right (fully hidden)
      transition={{ ease: "linear", duration: 0.2 }}
    >
      <div className={styles.blockBorder2} style={{ height: 55 }}>
        <button onClick={toggleMenu} type="button">
          <CloseOutlined className={styles.closeIcon} />
        </button>
      </div>
      <div className={styles.innerItemsBack}>
        <ul className={styles.listItems}>
          {menuItems.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key, react/jsx-no-comment-textnodes
            <div
              className={styles.blockBorder}
              key={item.label}
              onClick={() => closeMenuAndNavigate(item.path)}
              tabIndex={0}
              role="button"
            >
              <motion.li
                initial={{
                  x: "-100px",
                }}
                animate={{
                  x: 0,
                }}
                transition={{
                  duration: 0.4,
                }}
              >
                <NavLink to={item.path}>{item.label}</NavLink>
              </motion.li>
            </div>
          ))}
          {profile.email && (
            <div
              className={styles.blockBorder}
              key={t("services.order")}
              onClick={() => closeMenuAndNavigate("/order")}
              tabIndex={0}
              role="button"
            >
              <motion.li
                initial={{
                  x: "-100px",
                }}
                animate={{
                  x: 0,
                }}
                transition={{
                  duration: 0.4,
                }}
              >
                <NavLink to="/order">{t("services.order")}</NavLink>
              </motion.li>
            </div>
          )}
          <div className={styles.blockBorder}>
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy}
              onClick={handleAcademyClick}
            >
              {t("header.collections")}
              <CaretRightOutlined />
            </motion.li>
          </div>{" "}
          <div className={styles.blockBorder}>
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy}
              onClick={handleServiceClick}
            >
              {t("header.categories")}
              <CaretRightOutlined />
            </motion.li>
          </div>
        </ul>
        <LanguageSelectorBurger />
      </div>
      <AnimatePresence>
        {showAcademyBlock && (
          <CollectionBlock
            setShowAcademyBlock={setShowAcademyBlock}
            closeMenuAndNavigate={closeMenuAndNavigate}
            toggleMenu={toggleMenu}
          />
        )}
      </AnimatePresence>{" "}
      <AnimatePresence>
        {showServiceBlock && (
          <CategoryBlock
            setShowAcademyBlock={setShowServiceBlock}
            closeMenuAndNavigate={closeMenuAndNavigate}
            toggleMenu={toggleMenu}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export const Header = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const collections = useAppSelector(getCollectionsSelector);
  const categories = useAppSelector(getCategoriesSelector);
  const [isHeaderHidden, setHeaderHidden] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [collectionsItems, setcollectionsItems]: any[] = useState([]);
  const [categoryItems, setCategoryItems]: any[] = useState([]);
  const [culersVisible, setCulersVisible] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);
  const cart = JSON.parse(localStorage.getItem("cart") || "{}");
  const cartCount = useAppSelector(getCountSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const excludedPaths = [Routes.PROFILE, Routes.ORDER];
  const [isHoveredCol, setIsHoveredCol] = useState(false);
  const [isHoveredCat, setIsHoveredCat] = useState(false);
  const visible = useAppSelector(getVisiblelSelector);
  const keywordsData = useAppSelector(getKeywordslSelector);
  const [keywords, setKeywords] = useState("");
  const [like, setLike] = useState(false);
  const [loading, setLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const at = localStorage.getItem("at");

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const debouncedKeywords = useDebounce(keywords, 500);

  useEffect(() => {
    if (!like && debouncedKeywords) {
      setLike(true);
    }
    setLoading(true);
    dispatch(
      getKeywords({ params: `?keywords=${debouncedKeywords || null}` })
    ).then(() => {
      setLoading(false);
    });
  }, [debouncedKeywords, like, dispatch]);

  useEffect(() => {
    if (at) {
      dispatch(getProfile());
    }
  }, [at]);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getCollections());
    dispatch(setCount(cart.length));
  }, []);

  useEffect(() => {
    if (collections.length) {
      const result = collections.map((item, i) => {
        const obj = {
          key: i,
          href: `${RoutePath.collections}?collectionId=${item.id}`,
          value: item.id,
          label: item.name,
        };
        return obj;
      });
      setcollectionsItems(result);
    }
  }, [collections]);

  useEffect(() => {
    if (categories.length) {
      const result = categories.map((item, i) => {
        const obj = {
          key: i,
          href: `${RoutePath.categories}?categoryId=${item.id}`,
          value: item.id,
          label: item.name,
        };
        return obj;
      });
      setCategoryItems(result);
    }
  }, [categories]);

  const COLLECTIONS: IMenuItem[] = collectionsItems;
  const CATEGORIES: IMenuItem[] = categoryItems;

  const SUB_MENU: Record<string, IMenuItem[]> = {
    [AppRoutes.COLLECTIONS]: collectionsItems,
    [AppRoutes.CATEGORIES]: categoryItems,
  };

  const subMenu = useMemo(
    () => SUB_MENU[pathname.split("/")[1]],
    [pathname, SUB_MENU]
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        setHeaderHidden(true);
      } else {
        setHeaderHidden(false);
      }
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  const isMobile = screenWidth < 1000;
  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const onNavigateMain = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const closeMenuAndNavigate = (route: any) => {
    setMenuVisible(false);
    navigate(route);
  };

  return (
    <>
      <Layout.Header
        className={cn(styles.header, {
          [styles.hide]:
            pathname !== Routes.MAIN && isHeaderHidden && !isMobile,
        })}
      >
        {!isMobile && (
          <>
            <div className={styles.nav_top}>
              <LoginHeader />
            </div>
            <div className={styles.nav_middle}>
              <Link
                to={Routes.MAIN}
                className={styles.logo}
                onClick={onNavigateMain}
              >
                <img src={Links.logo} alt="logo" width={130} height={44} />
              </Link>
              <div className={styles.menu}>
                <div className={styles.menu_left}>
                  {COLLECTIONS.length ? (
                    <div
                      className={styles.link}
                      onMouseEnter={() => setIsHoveredCol(true)}
                      onMouseLeave={() => setIsHoveredCol(false)}
                      style={{
                        textDecoration: isHoveredCol ? "underline" : "none",
                        color: isHoveredCol ? "#fbc43f" : "white",
                        cursor: "pointer",
                      }}
                    >
                      {t("header.collections")}
                    </div>
                  ) : null}
                  {CATEGORIES.length ? (
                    <div
                      className={styles.link}
                      onMouseEnter={() => setIsHoveredCat(true)}
                      onMouseLeave={() => setIsHoveredCat(false)}
                      style={{
                        textDecoration: isHoveredCat ? "underline" : "none",
                        color: isHoveredCat ? "#fbc43f" : "white",
                        cursor: "pointer",
                      }}
                    >
                      {t("header.categories")}
                    </div>
                  ) : null}
                </div>
                <div className={styles.menu_right}>
                  {excludedPaths.includes(pathname as Routes) && (
                    <HeaderMenu
                      path={Routes.ORDER}
                      label={t("services.order")}
                    />
                  )}
                </div>
                <div className={styles.searchHeader}>
                  <div>
                    <IconButton
                      onClick={() => dispatch(setVisible(true))}
                      className={styles.iconButton}
                    >
                      <SearchOutlined
                        className={`${styles.icon} ${styles.shopCard}`}
                      />
                    </IconButton>
                  </div>
                  <div>
                    <IconButton onClick={() => navigate(RoutePath.cart)}>
                      <ShoppingCartOutlined
                        className={`${styles.icon} ${styles.shopCard}`}
                      />
                      {cartCount > 0 && (
                        <div className={styles.cartBadge}>{cartCount}</div>
                      )}
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            {/* <Breadcrumbs menu={subMenu} /> */}
          </>
        )}

        {isMobile && (
          <div>
            <div className={styles.middleBlock}>
              <div style={{ maxHeight: "60px" }}>
                <img
                  src={Links.logo}
                  alt="logo"
                  width={122}
                  height={40}
                  className={styles.logoSmall}
                  onClick={onNavigateMain}
                />
              </div>
              <div className={styles.smallMenu}>
                <Button
                  onClick={() => setCulersVisible((prev) => !prev)}
                  className={styles.culers}
                >
                  {t("profile")}
                </Button>
                <IconButton onClick={() => dispatch(setVisible(true))}>
                  <SearchOutlined
                    className={`${styles.icon} ${styles.shopCard}`}
                  />
                </IconButton>
                <button onClick={() => toggleMenu()} type="button">
                  <div className={styles.circle}>
                    <div className={styles.icon} />
                    <div className={styles.iconMiddle} />
                    <div className={styles.icon} />
                  </div>
                </button>
              </div>
            </div>
            <AnimatePresence mode="wait">
              {culersVisible && (
                <LoginHeaderMobile
                  culersVisible={culersVisible}
                  onCancel={() => setCulersVisible(false)}
                />
              )}
            </AnimatePresence>
            <div className={styles.styleLine} />
          </div>
        )}
        <AnimatePresence>
          {isMenuVisible && (
            <BurgerMenu
              toggleMenu={toggleMenu}
              closeMenuAndNavigate={closeMenuAndNavigate}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isHoveredCol && (
            <div
              className={styles.dropDownMenu}
              onMouseEnter={() => setIsHoveredCol(true)}
              onMouseLeave={() => setIsHoveredCol(false)}
            >
              <div className={styles.collections}>
                {collections.map((item) => (
                  <div key={item.id} className={styles.colName}>
                    <div
                      className={styles.item}
                      tabIndex={0}
                      role="button"
                      onClick={() => {
                        navigate(
                          `${RoutePath.collections}?collectionId=${item.id}`
                        );
                        setIsHoveredCol(false);
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
              {screenWidth > 1300 && (
                <HeaderCard
                  category={collections.length && collections[0]}
                  url={`${RoutePath.collections}?collectionId=${
                    collections.length && collections[0].id
                  }`}
                  click={() => setIsHoveredCol(false)}
                />
              )}
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isHoveredCat && (
            <div
              className={styles.dropDownMenu}
              onMouseEnter={() => setIsHoveredCat(true)}
              onMouseLeave={() => setIsHoveredCat(false)}
            >
              <div className={styles.collections}>
                {categories.map((item) => (
                  <div key={item.id} className={styles.colName}>
                    <div
                      className={styles.item}
                      tabIndex={0}
                      role="button"
                      onClick={() => {
                        navigate(
                          `${RoutePath.categories}?categoryId=${item.id}`
                        );
                        setIsHoveredCat(false);
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
              {screenWidth > 1300 && (
                <HeaderCard
                  category={categories.length && categories[0]}
                  url={`${RoutePath.categories}?categoryId=${
                    categories.length && categories[0].id
                  }`}
                  click={() => setIsHoveredCat(false)}
                />
              )}
            </div>
          )}
        </AnimatePresence>
      </Layout.Header>
      {visible && (
        <div className={styles.searchBlock}>
          <div className={styles.searchContent}>
            <div className={styles.searchHead}>
              <img src={Links.blueLogo} alt="" className={styles.logoHeader} />
              <Input
                type="search"
                placeholder={t("validateSms.search")}
                className={styles.input}
                addonAfter={<SearchOutlined />}
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
              <Button
                className={styles.btn}
                onClick={() => dispatch(setVisible(false))}
              >
                {t("cancel")}
              </Button>
            </div>
            {!like && (
              <>
                <div className={styles.might}>{t("might")}</div>
                <ClothesMight />
              </>
            )}
            <div className={styles.cardContent}>
              {like && keywordsData ? (
                keywordsData.map((item) => (
                  <ProductCardCustom data={item} key={item.id} />
                ))
              ) : (
                <div className={styles.spin}>{loading && <Loader />}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
