import { Button, Dropdown, MenuProps } from "antd";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import styles from "./LanguageSelector.module.scss";

const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
  LocalStorage.setItem(LocalStorageKey.Lang, language);
  window.location.reload();
};

export const LanguageSelector = () => {
  const lang = LocalStorage.getItem(LocalStorageKey.Lang);
  const { t } = useTranslation();

  const items: MenuProps["items"] = [
    {
      key: "en",
      type: "group",
      label: t("lang"),
    },
    {
      key: "ky",
      label: "Кыргызча",
    },
    {
      key: "ru",
      label: "Русский",
    },
    {
      key: "en",
      label: "English",
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: [lang ?? "ru"],
        onClick: (e) => changeLanguage(e.key),
      }}
      placement="bottomRight"
      className={styles.dropDown}
      overlayClassName={styles.menu}
      arrow={{ pointAtCenter: true }}
    >
      <Button className={styles.lang}>{lang ?? "ru"}</Button>
    </Dropdown>
  );
};
