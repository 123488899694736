import React from "react";
import { Form } from "antd";
import { SignUpType } from "@models/login";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";
import { Links } from "@enums/links";
import { RoutePath } from "@router/routePath";
import { signInGoogle, SignInFacebook } from "@store/slices";
import { ConfirmEmailModal } from "../modals/ConfirmEmailModal";
import { Forms } from "./forms";
import { YandexAuth } from "../login/YandexAuth";
import { SubmitButton } from "../components";
import { useRegister, useShowCodeModal } from "../hooks";
import { FormLayout } from "../layout";
import styles from "./Register.module.scss";

export const Register: React.FC = () => {
  const { t } = useTranslation();
  const { form, onFinish, onFinishFailed } = useRegister();
  const { onClose } = useShowCodeModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const responseSocial = ({ provider, data }: IResolveParams) => {
    if (!data) return;
    if (provider === "google") {
      const res = {
        token: data.access_token,
        type: data.token_type,
      };
      dispatch(signInGoogle({ values: res, navigate }));
    }
    if (provider === "facebook") {
      const res = {
        token: data.accessToken,
      };
      dispatch(SignInFacebook({ values: res, navigate }));
    }
  };

  return (
    <FormLayout>
      <Form<SignUpType>
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        className={styles.form}
        layout="vertical"
        form={form}
      >
        <Forms form={form} />
        <SubmitButton form={form}>
          <Trans i18nKey="register.submit" />
        </SubmitButton>
        <div className={styles.line}>
          <hr /> <span>{t("register.or")}</span> <hr />
        </div>
        <div className={styles.socialTitle}>{t("register.socialTitle")}</div>
        <div className={styles.oauthLogin}>
          <LoginSocialGoogle
            client_id={process.env.REACT_APP_CLIENT_ID || ""}
            scope="email profile"
            isOnlyGetToken
            typeResponse="accessToken"
            onResolve={(response: IResolveParams) => responseSocial(response)}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <div className={styles.authBlock}>
              <img className={styles.authIcon} src={Links.google} alt="" />
            </div>
          </LoginSocialGoogle>
          <YandexAuth />
          <LoginSocialFacebook
            appId={process.env.REACT_APP_CLIENT_FACEBOOK_ID || ""}
            isOnlyGetToken
            fieldsProfile="id,first_name,last_name,picture,email"
            onResolve={(response: IResolveParams) => responseSocial(response)}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <div className={styles.authBlock}>
              <img
                className={styles.authIcon}
                src={Links.facebookAuth}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          </LoginSocialFacebook>
          <IconButton
            className={styles.logoutBlock}
            onClick={() => navigate(RoutePath.main)}
          >
            <div className={styles.authBlock}>
              <img className={styles.authIcon} src={Links.logoutAuth} alt="" />
            </div>
          </IconButton>
        </div>
      </Form>
      <ConfirmEmailModal onClose={onClose} />
    </FormLayout>
  );
};
