/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Links } from "@enums/links";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ImagesAlt } from "@constants/alt";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "@ui-kit/selectors";
import { useNavigate } from "react-router-dom";
import { getProfileSelector } from "@store/selectors";
import { RoutePath } from "@router/routePath";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { logout } from "@store/slices";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import styles from "./LoginHeader.module.scss";

export const LoginHeader = () => {
  const { t } = useTranslation("translation");
  const profile = useAppSelector(getProfileSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout()).then(() => {
      LocalStorage.removeItem(LocalStorageKey.AccessToken);
    });
  };

  return (
    <div className={styles.headerLogin}>
      {profile.email ? (
        <>
          <Button
            className={styles.email}
            onClick={() => navigate(RoutePath.profile)}
          >
            <UserOutlined /> {profile.email}
          </Button>
          <Button
            className={styles.loginButton}
            onClick={() => onLogout()}
            icon={
              <img
                src={Links.logoHeader}
                alt={ImagesAlt}
                className={styles.imgButton}
              />
            }
          >
            {t("login.logout")}
          </Button>
        </>
      ) : (
        <>
          <Button
            icon={<UserOutlined />}
            className={styles.registerButton}
            onClick={() => navigate(RoutePath.login)}
          >
            {t("login.submit")}
          </Button>
          <Button
            className={styles.loginButton}
            onClick={() => navigate(RoutePath.register)}
            icon={
              <img
                src={Links.logoHeader}
                alt={ImagesAlt}
                className={styles.imgButton}
              />
            }
          >
            {t("login.register")}
          </Button>
        </>
      )}
      <LanguageSelector />
    </div>
  );
};
