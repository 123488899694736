import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { Links } from "@enums/links";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { getNetworksSelector } from "@store/selectors";
import { useEffect, useState } from "react";
import { getSocialNetworks } from "@store/slices";
import { Link } from "react-router-dom";
import { Routes } from "@enums/routes";
import styles from "./Footer.module.scss";

const SocialType: Record<string, string> = {
  instagram: Links.instagram,
  twitter: Links.twitter,
  facebook: Links.facebook,
  youtube: Links.youtube,
};

export const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const networks = useAppSelector(getNetworksSelector);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Вызываем обработчик события при монтировании компонента
    handleResize();

    // Очищаем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getSocialNetworks());
  }, []);

  return (
    <>
      <Layout.Footer className={styles.footer}>
        <div className={styles.bigTitle}>Fc barcelona</div>
        <div className={styles.background}>
          <div className={styles.logo} />
        </div>
        <div className={styles.socialMedia}>
          {networks.length ? (
            <>
              <div className={styles.text}>{t("footer.title")}</div>
              <div className={styles.socialNetworks}>
                {networks.map((network) => (
                  <a
                    key={network.id}
                    href={network.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={styles[network.social_network_type]}>
                      <img
                        src={SocialType[network.social_network_type]}
                        alt=""
                        width={28}
                      />
                      <div className={styles.url}>
                        @{network.title.replace(/\s/g, "").toLowerCase()}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          <div className={styles.footerCorporate}>
            <img src={Links.footerLogo} alt="" width={210} />
            <div className={styles.footerCorporateContainer}>
              <div>
                <span>{t("footer.copyright")}</span>
              </div>
              <div className={styles.footerCorporatelinks}>
                <Link to={Routes.FAQ}>{t("footer.help")}</Link>
                <Link to={Routes.CONTACT}>{t("footer.contact")}</Link>
                <Link to={Routes.PRIVACY}>{t("footer.policy")}</Link>
              </div>
            </div>
          </div>
        </div>
      </Layout.Footer>
      <div className={styles.breadcrumbs} />
    </>
  );
};
