import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Dropdown } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { IMenuItem } from "@utils/getLink";
import styles from "../../Header.module.scss";

export const HeaderMenu = ({
  icon,
  items,
  label,
  path,
}: {
  icon?: React.ReactNode;
  items?: IMenuItem[];
  label: string;
  path: string;
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const isActive = (to: string) => location.pathname.startsWith(to);
  const classes = isActive(path)
    ? `${styles.link} ${styles.active}`
    : styles.link;

  if (!items || !items.length) {
    return (
      <Link to={path} className={classes}>
        {icon} {t(label)}
      </Link>
    );
  }

  const menu = useMemo(
    () =>
      items.map((item) => ({
        key: item.key,
        href: item.href,
        label: <Link to={item.href}>{item.label}</Link>,
      })),
    [items]
  );

  return (
    <Dropdown
      menu={{ items: menu }}
      disabled={isActive(path)}
      overlayClassName={styles.dropdown}
      placement="bottomCenter"
      arrow={{ pointAtCenter: true }}
    >
      <div className={classes}>
        {icon} {label} <CaretDownOutlined />
      </div>
    </Dropdown>
  );
};
