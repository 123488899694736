import { Suspense, useEffect, useState } from "react";
import { Header } from "@ui-kit/header";
import { useLocation } from "react-router-dom";
import { Loader } from "@ui-kit/loader";
import { Footer } from "@ui-kit/footer";
import { SEO } from "@utils/SEO";
import { AppRouter } from "./providers";
import { LocalStorageKey, Routes } from "./enums";
import { LocalStorage } from "./localStorage";

export default function App() {
  const { pathname } = useLocation();
  const excludedPaths = [
    Routes.LOGIN,
    Routes.REGISTER,
    Routes.FORGOT_PASSWORD,
    Routes.CONFIRM_PASSWORD,
  ];
  const acceptCookie = LocalStorage.getItem(LocalStorageKey.AcceptCookie);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let value;

  if (pathname === Routes.MAIN) {
    value = screenWidth < 1000 ? 67 : 119;
  } else {
    value = screenWidth < 1000 ? 67 : 118;
  }

  return (
    <>
      <SEO
        title="Главная страница"
        url=""
        image="https://s3.barcelona.kg/static/academyLogo2.webp"
      />
      <Suspense fallback={<Loader />}>
        {!excludedPaths.includes(pathname as Routes) && <Header />}
        <div
          style={{
            marginTop:
              // eslint-disable-next-line no-nested-ternary
              value,
          }}
        >
          <AppRouter />
        </div>
        {!excludedPaths.includes(pathname as Routes) && <Footer />}
      </Suspense>
    </>
  );
}
