import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getCollections, getProducts } from "@store/slices";
import { useAppSelector } from "@hooks/useAppSelector";
import {
  getCategoriesSelector,
  getCollectionsSelector,
} from "@store/selectors";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "@router/routePath";
import { Ticker } from "@ui-kit/ticker";
import { Links } from "@enums/links";
import { ClothesList } from "@features/main";
import { SEO } from "@utils/SEO";
import classNames from "classnames";
import { CardUI } from "@ui-kit/card";
import styles from "./MainLayout.module.scss";

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

export const MainLayout = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const collection = useAppSelector(getCollectionsSelector);
  const category = useAppSelector(getCategoriesSelector);
  const recCollection = collection[collection.length - 1];
  const recCategory = category[category.length - 1];

  useEffect(() => {
    dispatch(getCollections());
    dispatch(getProducts());
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.mainBlock}>
      <SEO
        title={t("header.main")}
        url=""
        image="https://s3.barcelona.kg/static/academyLogo2.webp"
      />
      <div
        className={styles.banner}
        role="button"
        tabIndex={0}
        onClick={() =>
          collection.length &&
          navigate(`${RoutePath.collections}?collectionId=${recCollection.id}`)
        }
      >
        <img
          src={collection.length ? recCollection.image : ""}
          alt=""
          className={styles.img}
        />
        <div className={styles.title}>
          {collection.length ? recCollection.name : t("category.notCollection")}
        </div>
        {recCollection && (
          <Button
            className={classNames(styles.button, {
              [styles.ky]: i18n.language === "ky",
            })}
          >
            {t("category.button")} <ArrowRightOutlined />
          </Button>
        )}
      </div>
      <Ticker />
      <div className={styles.title}>{t("category.new")}</div>
      <ClothesList />
      {/* <div className={styles.title}>{t("header.collections")}</div> */}
      <div className={styles.collectionBlock}>
        {collection.length === 0 && (
          <div className={styles.notCategory}>
            {t("category.notCollection")}
          </div>
        )}
        {collection.slice(-3).map((col) => (
          <CardUI
            key={col.id}
            data={col}
            path={`${RoutePath.collections}?collectionId=${col.id}`}
          />
        ))}
      </div>
      <div className={styles.breadcrumbs} />
      <div
        className={`${styles.banner} ${styles.marginUp}`}
        role="button"
        tabIndex={0}
        onClick={() =>
          category.length &&
          navigate(`${RoutePath.categories}?categoryId=${recCategory.id}`)
        }
      >
        <img
          src={category.length ? recCategory.image : Links.banner}
          alt=""
          className={styles.img2}
        />
        <div className={styles.title}>
          {category.length ? recCategory.name : t("category.notCategory")}
        </div>
        {recCategory && (
          <Button
            className={classNames(styles.button, {
              [styles.ky]: i18n.language === "ky",
            })}
          >
            {t("category.button")} <ArrowRightOutlined />
          </Button>
        )}
      </div>
      <div className={styles.breadcrumbs2} />
      {/* <div className={styles.title}>{t("header.categories")}</div> */}
      <div className={styles.collectionBlock}>
        {category.length === 0 && (
          <div className={styles.notCategory}>{t("category.notCategory")}</div>
        )}
        {category.slice(-3).map((cat) => (
          <CardUI
            key={cat.id}
            data={cat}
            path={`${RoutePath.categories}?categoryId=${cat.id}`}
          />
        ))}
      </div>
    </div>
  );
};
