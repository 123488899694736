import { Trans, useTranslation } from "react-i18next";
import { Button, Form, notification } from "antd";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { FormInput } from "@ui-kit/formInput";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { getLoadingSelector, getProfileSelector } from "@store/selectors";
import { editProfile, getProfile } from "@store/slices";
import { IProfile } from "@enums/slices";
import { SEO } from "@utils/SEO";
import styles from "./ProfileLayout.module.scss";

const formatPhoneNumber = (phone: string | null) => {
  if (!phone) return null;
  return phone.startsWith("+") ? phone : `+${phone}`;
};

export const ProfileLayout = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getProfileSelector);
  const loading = useAppSelector(getLoadingSelector);

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...user, phone: user.phone || "" });
  }, [user, form]);

  const onFinish = async (values: IProfile) => {
    const { ...others } = values;
    const formattedPhone = formatPhoneNumber(others.phone);

    const updatedData: Partial<IProfile> = {
      name: others.name || "",
      lastname: others.lastname || "",
      phone: formattedPhone,
    };

    dispatch(editProfile(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(getProfile());
        setTouched(false);
        notification.success({
          message: t("alert.profileUpdated"),
        });
      }

      if (data.meta.requestStatus === "rejected") {
        notification.error({
          message: data.payload.response.data.message,
        });
      }
    });
  };

  return (
    <div className={styles.pageBlock}>
      <SEO title={t("data.profile")} url="" />

      <div className={styles.mainBlock}>
        <div className={styles.title}>{t("data.profile")}</div>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          className={styles.form}
          disabled={loading}
          onValuesChange={() => setTouched(true)}
        >
          <div className={styles.emailBlock}>
            <Form.Item name="name" label={t("data.name")}>
              <FormInput allowClear placeholder={t("data.name")} />
            </Form.Item>
            <Form.Item name="lastname" label={t("data.lastname")}>
              <FormInput
                allowClear
                type="text"
                placeholder={t("data.lastname")}
              />
            </Form.Item>
          </div>
          <div className={styles.emailBlock}>
            <Form.Item name="phone" label={t("responseModal.phone")}>
              <PhoneInput
                country="kg"
                inputStyle={{ width: "100%", height: 44 }}
              />
            </Form.Item>
            <Form.Item name="email" label={t("login.email")}>
              <FormInput placeholder={t("login.email")} disabled />
            </Form.Item>
          </div>

          <div className={styles.buttonBlock}>
            <Button
              className={styles.btn}
              htmlType="submit"
              loading={loading}
              disabled={!touched}
            >
              <Trans i18nKey="data.edit" />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
