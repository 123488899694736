import { useTranslation } from "react-i18next";
import { Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { SubmitButton } from "@ui-kit/components";
import { useAppSelector } from "@hooks/useAppSelector";
import { getPersonalSelector, getProfileSelector } from "@store/selectors";
import { useNavigate } from "react-router-dom";
import { createOrder, setCount } from "@store/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useTooltip } from "@hooks/useTooltip";
import { TotalBlock } from "../cart/TotalBlock";
import styles from "./Delivery.module.scss";

const { Option } = Select;

export const DeliveryLayout = () => {
  const cart = JSON.parse(localStorage.getItem("cart") || "[]");
  const [dostavka, setDostavka] = useState();
  const personalData = useAppSelector(getPersonalSelector);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useAppSelector(getProfileSelector);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const state = JSON.parse(localStorage.getItem("cart") || "[]");
  const sum = JSON.parse(localStorage.getItem("sum") || "[]");
  const { success } = useTooltip();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      const productIds = cart.map((item: any) => ({
        productId: item.id,
        quantity: item.buyQuantity,
        size: item.size || null,
      }));
      const pickup = {
        email: personalData.email,
        phone: personalData.phone,
        name: personalData.name,
        lastname: personalData.lastname,
      };
      const delivery = {
        ...pickup,
        address: {
          city: values.city,
          street: values.street,
          house: values.house,
          apartment: Number(values.apartment),
        },
      };
      const obj: any = {
        productIds,
        recepient: pickup,
        payment_method: "cash",
        order_type: personalData.buy_type,
        shipping_method: values.shipping_method,
      };
      const setCoun = () => {
        dispatch(setCount(0));
      };
      dispatch(createOrder({ values: obj, navigate, setCount: setCoun })).then(
        () => {
          success(t("validateSms.order"));
        }
      );
    },
    [dispatch]
  );

  const BUTTON_TEXT = {
    ORDER: "data.order",
    RESERVATION: "data.reserv",
    PAY: "data.pay",
  };

  return (
    <div className={styles.pageBlock}>
      <div className={styles.mainBlock}>
        <div className={styles.title}>{t("data.delivery")}</div>
        <div className={styles.contentBlock}>
          <Form
            onFinish={onFinish}
            autoComplete="off"
            className={styles.form}
            layout="vertical"
            form={form}
          >
            <div className={styles.selectBlock}>
              <Form.Item name="shipping_method">
                <Select
                  value={dostavka}
                  placeholder={t("data.choose")}
                  onChange={(values) => setDostavka(values)}
                >
                  <Option value="pickup">{t("data.vyvoz")}</Option>
                </Select>
              </Form.Item>
            </div>
            <div className={styles.buttonBlock}>
              <SubmitButton form={form}>
                {t(BUTTON_TEXT.RESERVATION)}
              </SubmitButton>
            </div>
          </Form>
          {screenWidth > 1200 && <TotalBlock state={state} sum={sum} />}
        </div>
      </div>
    </div>
  );
};
