import React, { ReactNode } from "react";
import { Form, FormInstance } from "antd";
import { useButtonDisabled } from "@hooks/useButtonDisabled";
import { ButtonRed } from "@ui-kit/buttons";
import styles from "./SubmitButton.module.scss";

type PropsType = {
  children: ReactNode;
  form: FormInstance;
};

export const SubmitButton: React.FC<PropsType> = ({ children, form }) => {
  const { isButtonDisabled } = useButtonDisabled(form);

  return (
    <div className={styles.mainButton}>
      <Form.Item>
        <div className={styles.buttonsContainer}>
          <ButtonRed
            type="primary"
            htmlType="submit"
            disabled={isButtonDisabled}
          >
            {children}
          </ButtonRed>
        </div>
      </Form.Item>
    </div>
  );
};
