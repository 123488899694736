export enum Links {
  line = "/icons/line.svg",
  logo = "/images/logo.svg",
  logoHeader = "/icons/barca.svg",
  rightDecorators = "/images/rightDecorators.svg",
  leftDecorators = "/images/leftDecorators.svg",
  preview = "/images/preview.svg",
  logout = "/icons/logout.png",
  youtube = "/icons/youtube.svg",
  backgroundImage = "/images/leftBackground.png",
  backgroundImage2 = "/images/rightBackground.png",
  facebook = "/icons/facebook.svg",
  instagram = "/icons/instagram.svg",
  twitter = "/icons/twitter.svg",
  banner = "/images/banner.png",
  arrow = "/icons/arrow.svg",
  gym = "/images/gym.jpg",
  form = "/images/form.jpg",
  present = "/images/present.jpg",
  yandexAuth = "/icons/yandexAuth.svg",
  facebookAuth = "/icons/facebookAuth.png",
  logoutAuth = "/icons/logoutAuth.svg",
  rec = "/icons/rec.svg",
  footerLogo = "/images/footerLogo.svg",
  google = "/icons/google.png",
  blueLogo = "/icons/blueLogo.svg",
}
