import { FC } from "react";
import { Helmet, HelmetProps } from "react-helmet-async";
import { useTranslation } from "react-i18next";

type SEOProps = {
  title?: string;
  titleNew?: string;
  description?: string;
  image?: string;
  url: string;
  keywords?: string;
} & HelmetProps;

const keywordsAll = `Barca Store, Barça Store, barca Store, академия барсы в бишкеке, Уникальный Проект в Центральной Азии, Барселона, Barca Store Kyrgyzstan, 
  Barca Store Кыргызстан, Store, Store, Футбольный клуб, Barcelona Store, Barca Store, Barca Academy, Academy Barcelona, Academy Barca`;

export const SEO: FC<SEOProps> = (props) => {
  const { i18n } = useTranslation();
  const {
    title,
    titleNew,
    description,
    keywords = "Barca store",
    url,
    image,
    ...others
  } = props;

  const desc =
    description ||
    "Откройте для себя Barça Store: Уникальный Магазин для Настоящих Болельщиков в Центральной Азии. Barça Store – это не просто магазин, это настоящий центр футбольной страсти, созданный для преданных поклонников футбола в самом сердце Центральной Азии. Здесь вы найдете эксклюзивную атрибутику, оригинальную экипировку и множество уникальных товаров, которые помогут вам ощутить себя частью легендарного клуба. Добро пожаловать в мир Barça, где каждый найдет что-то для себя.";

  const img = image || "https://s3.barcelona.kg/static/academyLogo2.webp";
  const urlBase = `https://experience.barcelona.kg${url || ""}`;

  return (
    <Helmet {...others}>
      <title>{!titleNew ? `Barça Store - ${title}` : titleNew}</title>
      <meta name="description" content={desc} />
      <meta name="keywords" content={`${keywords}, ${keywordsAll}`} />
      <link rel="canonical" href={urlBase} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={img} />
      <meta name="twitter:url" content={urlBase} />
      <meta name="twitter:site" content="@FCB" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={urlBase} />
      <meta property="og:site_name" content="Barca Experience" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={i18n.language || "ru"} />
      <meta property="og:locale:alternate" content="ru_RU" />

      <meta name="robots" content="index, follow" />
      <meta httpEquiv="content-language" content={i18n.language || "ru"} />
    </Helmet>
  );
};
