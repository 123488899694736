import { useCallback, useEffect } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfirmPasswordType } from "@models/login";
import { AppDispatch } from "@store/index";
import { confirmPassword, setPassword } from "@store/slices/barca/barcaSlice";
import { Form, FormInstance } from "antd";
import { useAppSelector } from "@hooks/useAppSelector";
import { getPasswordSelector } from "@store/selectors";

type ReturnType = {
  password: string;
  form: FormInstance;
  onFinish: (values: ConfirmPasswordType) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity<ConfirmPasswordType>) => void;
};

export const useConfirmPassword = (): ReturnType => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const passwordValue = Form.useWatch("password", form);
  const password = useAppSelector(getPasswordSelector);
  const searchString = new URLSearchParams(window.location.search);
  const key = searchString.get("key");

  useEffect(() => {
    if (passwordValue) {
      dispatch(setPassword(passwordValue));
    }
  }, [passwordValue]);

  const onFinish = useCallback(
    (values: ConfirmPasswordType) => {
      // eslint-disable-next-line no-param-reassign
      values.resetToken = key || "";
      const { confirmPassword: value, ...data } = values;
      dispatch(confirmPassword({ values: data, navigate }));
    },
    [dispatch, key]
  );

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<ConfirmPasswordType>) => {
      console.log("Failed:", errorInfo);
    },
    []
  );

  return {
    form,
    password,
    onFinish,
    onFinishFailed,
  };
};
