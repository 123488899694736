import { AuthApi } from "@constants/api";
import { SliceConstants, SliceName } from "@constants/slices";
import { LocalStorageKey } from "@enums/localStorage";
import { Routes } from "@enums/routes";
import {
  ContactType,
  ICategories,
  IClothes,
  ICollections,
  IContact,
  IContactRes,
  IInfoPage,
  INetworks,
  IOrders,
  IProfile,
  InfoPageType,
  InfoPageViewType,
} from "@enums/slices";
import { LocalStorage } from "@localStorage/localStorage";
import {
  CheckCode,
  ConfirmSendPasswordType,
  ForgotPasswordType,
  SendApplication,
  SendCode,
  SignInType,
  SignSendUpType,
} from "@models/login";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "@utils/axios";
import { message } from "antd";

export interface BarcaState {
  loading: boolean;
  email: string;
  password: string;
  emailConfirm: boolean;
  categories: ICategories[];
  collections: ICollections[];
  clothes: IClothes[];
  clotheAmount: number;
  networks: INetworks[];
  personal: any;
  clothe: IClothes | null;
  clotheLoading: boolean;
  keywords: IClothes[];
  cartCount: number;
  profile: IProfile;
  personalVisible: boolean;
  visible: boolean;
  orders: IOrders[];
  contact: {
    loading: boolean;
    items: IContactRes[];
    error?: string;
  };
  infoPage: {
    loading: boolean;
    items: IInfoPage[];
    error?: string;
  };
}

const initialState: BarcaState = {
  loading: false,
  email: "",
  password: "",
  emailConfirm: false,
  categories: [],
  keywords: [],
  collections: [],
  clothes: [],
  clotheLoading: false,
  clotheAmount: 0,
  networks: [],
  visible: false,
  orders: [],
  clothe: null,
  personalVisible: false,
  personal: null,
  cartCount: 0,
  profile: {
    id: "",
    name: "",
    lastname: "",
    email: "",
    phone: "",
  },
  contact: {
    items: [],
    loading: false,
  },
  infoPage: {
    items: [],
    loading: false,
  },
};

export const signIn = createAsyncThunk(
  SliceConstants.SignIn,
  async function (
    {
      values: props,
      navigate,
      setAlert,
    }: { values: SignInType; navigate: any; setAlert: any },
    { rejectWithValue }
  ) {
    try {
      const { data } = await $api.post(AuthApi.Login, props);
      LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
      navigate(Routes.MAIN);
      return data;
    } catch (error: any) {
      setAlert(error.response.data.message, "error");
      return rejectWithValue(error);
    }
  }
);

export const getProfile = createAsyncThunk(
  SliceConstants.GetProfile,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await $api.get(AuthApi.Profile);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const editProfile = createAsyncThunk(
  SliceConstants.PutProfile,
  async function (props: Partial<IProfile>, { rejectWithValue }) {
    try {
      const { data } = await $api.put(AuthApi.Profile, props);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getCollections = createAsyncThunk(
  SliceConstants.GetCollections,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await $api.get(AuthApi.Collections);
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getProducts = createAsyncThunk(
  SliceConstants.GetProducts,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await $api.get(AuthApi.Products);
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getProductsById = createAsyncThunk(
  SliceConstants.GetProductsById,
  async function ({ id }: { id: string }, { rejectWithValue }) {
    try {
      const { data } = await $api.get(`${AuthApi.Clothes}/${id}`);
      return data.product;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getClothes = createAsyncThunk(
  SliceConstants.GetClothes,
  async function ({ params }: { params: string }, { rejectWithValue }) {
    try {
      const { data } = await $api.get(`${AuthApi.Clothes}${params}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getKeywords = createAsyncThunk(
  SliceConstants.GetKeywords,
  async function ({ params }: { params: string }, { rejectWithValue }) {
    try {
      const { data } = await $api.get(`${AuthApi.Keywords}${params}`);
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getSocialNetworks = createAsyncThunk(
  SliceConstants.GetSocial,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await $api.get(`${AuthApi.Social}?type=store`);
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getOrders = createAsyncThunk(
  SliceConstants.GetOrder,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await $api.get(AuthApi.Orders);
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  SliceConstants.Logout,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await $api.post(AuthApi.Logout, {});
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendCode = createAsyncThunk(
  SliceConstants.SendCode,
  async function (props: SendCode, { rejectWithValue }) {
    try {
      const { data } = await $api.post(AuthApi.SendCode, props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkCode = createAsyncThunk(
  SliceConstants.CheckCode,
  async function (props: CheckCode, { rejectWithValue }) {
    try {
      const { data } = await $api.post(AuthApi.CheckCode, props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendApplication = createAsyncThunk(
  SliceConstants.Application,
  async function (props: SendApplication, { rejectWithValue }) {
    try {
      const { data } = await $api.post(AuthApi.Application, props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signUp = createAsyncThunk(
  SliceConstants.SignUp,
  async function ({
    values: props,
    navigate,
    setAlert,
  }: {
    values: SignSendUpType;
    navigate: any;
    setAlert: any;
  }) {
    try {
      const { data } = await $api.post(AuthApi.Register, props);
      navigate(Routes.MAIN);
      LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
      return data;
    } catch (error: any) {
      setAlert(error.response.data.message, "error");
      return null;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  SliceConstants.ForgotPassword,
  async function (
    { values: props, navigate }: { values: ForgotPasswordType; navigate: any },
    { rejectWithValue }
  ) {
    try {
      const { data } = await $api.post(AuthApi.ForgotPassword, props);
      navigate(Routes.CONFIRM_PASSWORD);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signInGoogle = createAsyncThunk(
  SliceConstants.SignInGoogle,
  async (
    { values: props, navigate }: { values: any; navigate: any },
    { rejectWithValue }
  ) => {
    const queryString = Object.keys(props)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(props[key])}`
      )
      .join("&");
    try {
      const { data } = await $api.get(
        `${AuthApi.LoginByGoogle}?${queryString}`
      );
      if (data.accessToken) {
        navigate(Routes.MAIN);
      }
      LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
      return data;
    } catch (error: any) {
      message.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const SignInFacebook = createAsyncThunk(
  SliceConstants.SignInFacebook,
  async (
    { values: props, navigate }: { values: any; navigate: any },
    { rejectWithValue }
  ) => {
    try {
      const queryString = Object.keys(props)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(props[key])}`
        )
        .join("&");
      const { data } = await $api.get(
        `${AuthApi.LoginByFacebook}?${queryString}`
      );
      if (data.accessToken) {
        navigate(Routes.MAIN);
      }
      LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
      return data;
    } catch (error: any) {
      message.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const SignInYandex = createAsyncThunk(
  SliceConstants.SignInYandex,
  async (
    { code, navigate }: { code: string; navigate: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(`${AuthApi.LoginByYandex}`, {
        params: { code },
      });

      if (data.accessToken) {
        LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
        navigate(Routes.MAIN);
      }

      return data;
    } catch (error: any) {
      message.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createOrder = createAsyncThunk(
  SliceConstants.Order,
  async function (
    {
      values: props,
      navigate,
      setCount,
    }: { values: any; navigate: any; setCount: any },
    { rejectWithValue }
  ) {
    try {
      const { data } = await $api.post(AuthApi.Orders, props);
      if (
        props.shipping_method === "pickup" ||
        props.order_type === "reservation"
      ) {
        navigate(Routes.MAIN);
      }
      localStorage.removeItem("cart");
      setCount();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmPassword = createAsyncThunk(
  SliceConstants.ConfirmPassword,
  async function (
    {
      values: props,
      navigate,
    }: { values: ConfirmSendPasswordType; navigate: any },
    { rejectWithValue }
  ) {
    try {
      const { data } = await $api.post(AuthApi.ConfirmPassword, props);
      navigate(Routes.LOGIN);
      LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getContact = createAsyncThunk(
  SliceConstants.GetContact,
  async ({ type }: { type: ContactType }, { rejectWithValue }) => {
    try {
      const { data } = await $api.get<{ items: IContact[] }>(
        `${AuthApi.Contact}/${type}`
      );
      return data.items.map((item) => ({
        ...item,
        workingHours: item.workingHours.map((contact, idx) => {
          const [key, ...value] = contact.split(":").map((part) => part.trim());
          return { id: idx, label: key, text: value.join(":") };
        }),
      }));
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getInfoPage = createAsyncThunk(
  SliceConstants.GetInfoPage,
  async (
    { type, view }: { type: InfoPageType; view: InfoPageViewType },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(
        `${AuthApi.InfoPage}?info_page_type=${type}&view_type=${view}`
      );
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const barcaSlice = createSlice({
  name: SliceName.Barca,
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    setPassword: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        password: action.payload,
      };
    },
    setCount: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        cartCount: action.payload,
      };
    },
    setPersonalData: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        personal: action.payload,
      };
    },
    setPersonal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        personalVisible: action.payload,
      };
    },
    setVisible: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        visible: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      return {
        ...state,
        profile: payload,
      };
    });
    builder.addCase(getProfile.rejected, (state) => {
      return {
        ...state,
        profile: {
          id: "",
          name: "",
          lastname: "",
          email: "",
          phone: "",
        },
      };
    });
    builder.addCase(logout.fulfilled, (state) => {
      return {
        ...state,
        profile: {
          id: "",
          name: "",
          lastname: "",
          email: "",
          phone: "",
        },
      };
    });
    builder.addCase(checkCode.fulfilled, (state, { payload }) => {
      return {
        ...state,
        emailConfirm: payload.success,
      };
    });
    builder.addCase(checkCode.rejected, (state) => {
      return {
        ...state,
        emailConfirm: false,
      };
    });
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      return {
        ...state,
        categories: payload,
      };
    });
    builder.addCase(getProducts.rejected, (state) => {
      return {
        ...state,
        categories: [],
      };
    });
    builder.addCase(getCollections.fulfilled, (state, { payload }) => {
      return {
        ...state,
        collections: payload,
      };
    });
    builder.addCase(getCollections.rejected, (state) => {
      return {
        ...state,
        collections: [],
      };
    });
    builder.addCase(getClothes.fulfilled, (state, { payload }) => {
      return {
        ...state,
        clothes: payload.items,
        clotheAmount: payload.amount,
      };
    });
    builder.addCase(getClothes.rejected, (state) => {
      return {
        ...state,
        clothes: [],
        clotheAmount: 0,
      };
    });
    builder.addCase(getKeywords.fulfilled, (state, { payload }) => {
      return {
        ...state,
        keywords: payload,
        loading: false,
      };
    });
    builder.addCase(getKeywords.pending, (state) => {
      return {
        ...state,
        loading: true,
        keywords: [],
      };
    });
    builder.addCase(getKeywords.rejected, (state) => {
      return {
        ...state,
        keywords: [],
        loading: false,
      };
    });
    builder.addCase(getProductsById.fulfilled, (state, { payload }) => {
      return {
        ...state,
        clothe: payload,
        clotheLoading: false,
      };
    });
    builder.addCase(getProductsById.pending, (state, { payload }) => {
      return {
        ...state,
        clotheLoading: true,
      };
    });
    builder.addCase(getProductsById.rejected, (state) => {
      return {
        ...state,
        clothe: null,
        clotheLoading: false,
      };
    });
    builder.addCase(getSocialNetworks.fulfilled, (state, { payload }) => {
      return {
        ...state,
        networks: payload,
      };
    });
    builder.addCase(getSocialNetworks.rejected, (state) => {
      return {
        ...state,
        networks: [],
      };
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      return {
        ...state,
        orders: payload,
      };
    });
    builder.addCase(getOrders.rejected, (state) => {
      return {
        ...state,
        orders: [],
      };
    });
    builder.addCase(getContact.pending, (state) => {
      return {
        ...state,
        contact: {
          ...state.contact,
          loading: true,
        },
      };
    });
    builder.addCase(getContact.fulfilled, (state, { payload }) => {
      return {
        ...state,
        contact: {
          items: payload,
          loading: false,
        },
      };
    });
    builder.addCase(getContact.rejected, (state) => {
      return {
        ...state,
        contact: {
          loading: false,
          items: [],
        },
      };
    });
    builder.addCase(getInfoPage.pending, (state) => {
      return {
        ...state,
        infoPage: {
          ...state.infoPage,
          loading: true,
        },
      };
    });
    builder.addCase(getInfoPage.fulfilled, (state, { payload }) => {
      return {
        ...state,
        infoPage: {
          items: payload,
          loading: false,
        },
      };
    });
    builder.addCase(getInfoPage.rejected, (state) => {
      return {
        ...state,
        infoPage: {
          loading: false,
          items: [],
        },
      };
    });
  },
});

export const {
  setEmail,
  setPassword,
  setCount,
  setPersonalData,
  setPersonal,
  setVisible,
} = barcaSlice.actions;

export default barcaSlice.reducer;
