export const SliceConstants = {
  SignIn: "barcaSlice/signin",
  GetTrainersB: "barcaSlice/trainersB",
  GetTrainersJ: "barcaSlice/trainersJ",
  GetProducts: "barcaSlice/products",
  GetProductsById: "barcaSlice/productsById",
  GetClothes: "barcaSlice/clothes",
  GetKeywords: "barcaSlice/keywords",
  GetSocial: "barcaSlice/social",
  GetOrder: "barcaSlice/order",
  GetFunders: "barcaSlice/funders",
  SignUp: "barcaSlice/signup",
  SendCode: "barcaSlice/sendCode",
  GetContact: "barcaSlice/contact",
  GetInfoPage: "barcaSlice/infoPage",
  CheckCode: "barcaSlice/checkCode",
  Application: "barcaSlice/application",
  ForgotPassword: "barcaSlice/forgotPassword",
  ConfirmPassword: "barcaSlice/confirmPassword",
  GetProfile: "barcaSlice/profile",
  Logout: "barcaSlice/logout",
  PutProfile: "barcaSlice/putprofile",
  Order: "barcaSlice/Order",
  GetCollections: "barcaSlice/collections",
  SignInGoogle: "barcaSlice/signInGoogle",
  SignInFacebook: "barcaSlice/SignInFacebook",
  SignInVK: "barcaSlice/signInVK",
  SignInYandex: "barcaSlice/SignInYandex",
};

export const SliceName = {
  Auth: "auth",
  Barca: "barca",
};
