import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ICategories, ICollections } from "@enums/slices";
import styles from "./Card.module.scss";

type CardUIProps = {
  path: string;
  data: ICategories | ICollections;
};

export const CardUI: FC<CardUIProps> = (props) => {
  const { path, data } = props;
  const { t } = useTranslation();

  return (
    <Link to={path}>
      <div className={styles.card}>
        <img src={data.image} alt="" className={styles.img} />
        <div className={styles.content}>
          <div className={styles.ex}>{t("category.ex")}</div>
          <div className={styles.title}>
            {data.name ? data.name : t("category.presents")}
          </div>
        </div>
      </div>
    </Link>
  );
};
