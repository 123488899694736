import { Trans, useTranslation } from "react-i18next";
import { Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { SubmitButton } from "@ui-kit/components";
import { setPersonalData } from "@store/slices";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { Routes } from "@enums/routes";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { getProfileSelector } from "@store/selectors";
import { FormInputWhite } from "@ui-kit/formInputWhite";
import { TotalBlock } from "../cart/TotalBlock";
import styles from "./PersonalDataLayout.module.scss";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

export const PersonalDataLayout = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfileSelector);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const state = JSON.parse(localStorage.getItem("cart") || "[]");
  const sum = JSON.parse(localStorage.getItem("sum") || "[]");

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    form.setFieldValue("name", profile.name);
    form.setFieldValue("lastname", profile.lastname);
    form.setFieldValue("email", profile.email);
  }, [profile]);

  const onFinish = useCallback((values: any) => {
    dispatch(setPersonalData({ ...values, phone: Number(values.phone) }));
    navigate(Routes.DELIVERY);
  }, []);

  return (
    <div className={styles.pageBlock}>
      <div className={styles.mainBlock}>
        <div className={styles.title}>{t("data.personal")}</div>
        <div className={styles.contentBlock}>
          <Form
            onFinish={onFinish}
            autoComplete="off"
            className={styles.form}
            layout="vertical"
            form={form}
          >
            {screenWidth > 500 ? (
              <div className={styles.emailBlock}>
                <Form.Item name="name">
                  <FormInputWhite
                    allowClear
                    type="text"
                    placeholder={t("data.name")}
                  />
                </Form.Item>
                <Form.Item name="lastname">
                  <FormInputWhite
                    allowClear
                    type="text"
                    placeholder={t("data.lastname")}
                  />
                </Form.Item>
              </div>
            ) : (
              <>
                <Form.Item name="name">
                  <FormInputWhite
                    allowClear
                    type="text"
                    placeholder={t("data.name")}
                  />
                </Form.Item>
                <Form.Item name="lastname">
                  <FormInputWhite
                    allowClear
                    type="text"
                    placeholder={t("data.lastname")}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите email",
                },
                {
                  type: "email",
                  message: "Введите корректный email",
                },
              ]}
            >
              <FormInputWhite
                allowClear
                placeholder={t("login.email")}
                disabled
              />
            </Form.Item>
            <Form.Item name="phone" className={styles.phone}>
              <PhoneInput inputStyle={{ width: "100%" }} country="kg" />
            </Form.Item>
            <div className={styles.selectBlock}>
              <Form.Item name="buy_type">
                <Select
                  value={type}
                  placeholder={t("data.choose2")}
                  onChange={(values) => setType(values)}
                >
                  <Option value="reservation">{t("data.bron")}</Option>
                </Select>
              </Form.Item>
            </div>
            <div className={styles.buttonBlock}>
              <SubmitButton form={form}>
                <Trans i18nKey="data.continue" />
              </SubmitButton>
            </div>
          </Form>
          {screenWidth > 1200 && <TotalBlock state={state} sum={sum} />}
        </div>
      </div>
    </div>
  );
};
