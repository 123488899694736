import React, { useState, useEffect } from "react";
import { useShowCodeModal } from "@features/auth/hooks";
import { useTranslation } from "react-i18next";
import { RESEND_TIME } from "@constants/length";
import { Links } from "@enums/links";
import { Button } from "antd";
import styles from "./TimerComponent.module.scss";

export const TimerComponent = () => {
  const { t } = useTranslation();
  const [secondsRemaining, setSecondsRemaining] = useState(RESEND_TIME);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isTimerFinished, setIsTimerFinished] = useState(false);
  const { sendEmailCode } = useShowCodeModal();

  const startTimer = () => {
    sendEmailCode();
    setSecondsRemaining(RESEND_TIME);
    setIsResendDisabled(true);
    setIsTimerFinished(false);
  };

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timer = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    setIsTimerFinished(true);
    setIsResendDisabled(false);
    return undefined;
  }, [secondsRemaining]);

  const stub = () => {
    return undefined;
  };

  return (
    <div className={styles.timer}>
      <span className={styles.forgotPass}>
        {secondsRemaining > 0 ? `0:${secondsRemaining}` : secondsRemaining}
      </span>
      <img src={Links.line} alt="" className={styles.logo} />
      <Button
        className={styles.btn}
        onClick={!isResendDisabled ? startTimer : stub}
      >
        {t("forgotPassword.resend")}
      </Button>
    </div>
  );
};
