import { Link } from "react-router-dom";
import styles from "./HeaderCard.module.scss";

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

export const HeaderCard = ({ category, url, click }: any) => {
  return (
    <div
      className={styles.cardContainer}
      onClick={click}
      role="button"
      tabIndex={0}
    >
      <Link to={url}>
        <div className={styles.card}>
          <img src={category.image} alt="" className={styles.img} />
          <div className={styles.title}>{category.name}</div>
        </div>
      </Link>
    </div>
  );
};
