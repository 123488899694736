import styles from "./NewsCard.module.scss";

export const Ticker = () => {
  return (
    <div className={styles.marquee}>
      <div className={styles.marqueeContent} data-text="Blaugrana passion ·">
        Blaugrana passion ·
      </div>
    </div>
  );
};
