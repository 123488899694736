import { useEffect, useState } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getClothes, getProducts } from "@store/slices";
import { ProductCard } from "@ui-kit/productCard";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FilterSelects } from "@features/categories";
import { useAppSelector } from "@hooks/useAppSelector";
import {
  getCategoriesSelector,
  getClothesAmountSelector,
  getClothesSelector,
} from "@store/selectors";
import { useTranslation } from "react-i18next";
import { Loader } from "@ui-kit/loader";
import { CustomPagination } from "@ui-kit/pagination";
import { SEO } from "@utils/SEO";
import { RoutePath } from "@router/routePath";
import styles from "./CategoryLayout.module.scss";

export const CategoryLayout = () => {
  const [categoryData, setCategoryData]: any = useState();
  const { t } = useTranslation();
  const location = useLocation();
  const url = location.search;
  const params = new URLSearchParams(url);
  const category = decodeURIComponent(params.get("categoryId") || "");
  const skip = params.get("skip") || 0;
  const pageSize = params.get("limit") || 20;
  const pageNum = params.get("page") || 1;
  const clothes = useAppSelector(getClothesSelector);
  const [loading, setLoading] = useState(false);
  const categoriesList = useAppSelector(getCategoriesSelector);
  const amount = useAppSelector(getClothesAmountSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePageChange = (page: number, pageSizeNum: number) => {
    searchParams.set("skip", ((page - 1) * pageSizeNum).toString());
    searchParams.set("limit", pageSizeNum.toString());
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  function findItemByName(name: any) {
    return categoriesList.find((item) => String(item.id) === name);
  }

  useEffect(() => {
    const categoryResult = findItemByName(category);
    setCategoryData(categoryResult);
  }, [categoriesList, category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [skip]);

  useEffect(() => {
    dispatch(getProducts());
    handlePageChange(Number(pageNum), Number(pageSize));
  }, [url]);

  useEffect(() => {
    if (!category && categoriesList.length) {
      navigate(`${RoutePath.categories}?categoryId=${categoriesList[0].id}`);
    }
  }, [categoriesList]);

  useEffect(() => {
    const requestParams = new URLSearchParams(url);
    requestParams.delete("page");
    setLoading(true);
    dispatch(getClothes({ params: `?${requestParams.toString()}` })).then(
      () => {
        setLoading(false);
      }
    );
  }, [url]);

  return (
    <div className={styles.mainBlock}>
      <SEO
        title={t("header.categories")}
        url={`/categories?categoryId=${categoryData && categoryData.id}`}
      />

      <div className={styles.block}>
        <img
          src={categoryData && categoryData.image}
          alt=""
          className={styles.categoryImage}
        />
        <div className={styles.title}>{categoryData && categoryData.name}</div>
      </div>
      <div className={styles.filter}>
        <FilterSelects skip={skip} pageSize={pageSize} pageNum={pageNum} />
      </div>
      <div className={styles.cardBlcok}>
        {clothes.length ? (
          clothes.map((item) => <ProductCard data={item} key={item.id} />)
        ) : (
          <div className={styles.spin}>
            {loading ? (
              <Loader />
            ) : (
              <span className={styles.emptyDesc}>{t("category.form")}</span>
            )}
          </div>
        )}
      </div>
      <div className={styles.pagination}>
        {amount >= 19 && (
          <CustomPagination
            current={Number(pageNum)}
            pageSize={Number(pageSize)}
            total={amount}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
