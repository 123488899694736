import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import barcaSlice from "./slices/barca/barcaSlice";
import authSlice from "./slices/auth/authSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  barca: barcaSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
