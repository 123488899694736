import { useTranslation } from "react-i18next";
import { useAppSelector } from "@hooks/useAppSelector";
import { getOrderslSelector } from "@store/selectors";
import { useEffect } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getOrders } from "@store/slices";
import { Empty } from "antd";
import { Links } from "@enums/links";
import styles from "./Order.module.scss";

export const OrderLayout = () => {
  const { t } = useTranslation();
  const orders = useAppSelector(getOrderslSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOrders());
    window.scrollTo(0, 0);
  }, []);

  function translatePaymentMethod(paymentMethod: string) {
    switch (paymentMethod.toLowerCase()) {
      case "card":
        return t("order.card");
      default:
        return t("order.cash");
    }
  }

  function translateAction(action: string) {
    switch (action.toLowerCase()) {
      case "reservation":
        return t("order.reservation");
      default:
        return t("order.buying");
    }
  }

  function translateDeliveryMethod(method: string) {
    switch (method.toLowerCase()) {
      case "pickup":
        return t("order.pickup");
      default:
        return t("order.delivery");
    }
  }

  function translatePaymentStatus(status: string) {
    switch (status.toLowerCase()) {
      case "unpaid":
        return t("order.unpaid");
      default:
        return t("order.paid");
    }
  }

  function classPaymentStatus(status: string) {
    switch (status.toLowerCase()) {
      case "unpaid":
        return styles.unpaid;
      default:
        return styles.paid;
    }
  }

  return (
    <div className={styles.pageBlock}>
      <div className={styles.mainBlock}>
        <div className={styles.title}>{t("order.title")}</div>
        <div className={styles.contentBlock}>
          {orders.length ? (
            orders.map((item) => (
              <div className={styles.priceBlock} key={item.id}>
                {item.order_items.map((elem) => (
                  <div className={styles.priceRow} key={elem.product.id}>
                    <div className={styles.total}>{elem.product.name}</div>
                    <div className={styles.res}>
                      {elem.quantity} {t("pc")}
                    </div>
                    <div className={styles.sum}>
                      {elem.true_price} {t("som")}
                    </div>
                  </div>
                ))}
                <div className={styles.priceRow}>
                  <div className={styles.total}>{t("order.num")}</div>
                  <div className={styles.res}>{item.order_number}</div>
                </div>
                <div className={styles.priceRow}>
                  <div className={styles.total}>{t("order.status")}</div>
                  <div
                    className={`${styles.res} ${classPaymentStatus(
                      item.sub_status
                    )}`}
                  >
                    {translatePaymentStatus(item.sub_status)}
                  </div>
                </div>
                <div className={styles.priceRow}>
                  <div className={styles.total}>{t("order.order")}</div>
                  <div className={styles.res}>
                    {translateAction(item.order_type)}
                  </div>
                </div>
                <div className={styles.priceRow}>
                  <div className={styles.total}>{t("order.deliver")}</div>
                  <div className={styles.res}>
                    {translateDeliveryMethod(item.shipping_method)}
                  </div>
                </div>
                <div className={styles.priceRow}>
                  <div className={styles.total}>{t("order.buy")}</div>
                  <div className={styles.res}>
                    {translatePaymentMethod(item.payment_method)}
                  </div>
                </div>
                <div className={styles.priceRowLast}>
                  <div className={styles.total}>{t("cart.total")}</div>
                  <div className={styles.sum}>
                    {item.cost} {t("som")}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Empty
              image={Links.logoHeader}
              description={
                <span className={styles.emptyDesc}>{t("empty")}</span>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
