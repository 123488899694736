import { RegisterPage } from "@pages/RegisterPage";
import { AppRoutes, Routes } from "@enums/routes";
import { RouteProps } from "react-router-dom";
import { LoginPage } from "@pages/LoginPage";
import { MainPage } from "@pages/MainPage";
import { ForgotPasswordPage } from "@pages/ForgotPasswordPage";
import { ConfirmPasswordPage } from "@pages/ConfirmPasswordPage";
import { CategoriesPage } from "@pages/CategoriesPage";
import { CollectionsPage } from "@pages/CollectionsPage";
import { CardPage } from "@pages/CardPage";
import { CartPage } from "@pages/CartPage";
import { DeliveryPage } from "@pages/PayOrderPages/DeliveryPage";
import { PersonalDataPage } from "@pages/PayOrderPages/PersonalDataPage";
import { ProfilePage } from "@pages/ProfilePage";
import { OrderPage } from "@pages/OrderPage";
import { FAQPage } from "@pages/FAQPage";
import { ContactPage } from "@pages/ContactPage";
import { PrivacyPage } from "@pages/PrivacyPage";

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: Routes.MAIN,
  [AppRoutes.CART]: Routes.CART,
  [AppRoutes.ORDER]: Routes.ORDER,
  [AppRoutes.LOGIN]: Routes.LOGIN,
  [AppRoutes.DETAILS]: Routes.DETAILS,
  [AppRoutes.REGISTER]: Routes.REGISTER,
  [AppRoutes.CATEGORIES]: Routes.CATEGORIES,
  [AppRoutes.COLLECTIONS]: Routes.COLLECTIONS,
  [AppRoutes.FORGOT_PASSWORD]: Routes.FORGOT_PASSWORD,
  [AppRoutes.CONFIRM_PASSWORD]: Routes.CONFIRM_PASSWORD,
  [AppRoutes.PERSONAL_DATA]: Routes.PERSONAL_DATA,
  [AppRoutes.DELIVERY]: Routes.DELIVERY,
  [AppRoutes.PROFILE]: Routes.PROFILE,
  [AppRoutes.FAQ]: Routes.FAQ,
  [AppRoutes.PRIVACY]: Routes.PRIVACY,
  [AppRoutes.CONTACT]: Routes.CONTACT,
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.LOGIN]: {
    path: RoutePath.login,
    element: <LoginPage />,
  },
  [AppRoutes.CART]: {
    path: RoutePath.cart,
    element: <CartPage />,
  },
  [AppRoutes.PROFILE]: {
    path: RoutePath.profile,
    element: <ProfilePage />,
  },
  [AppRoutes.FAQ]: {
    path: RoutePath["asked-questions"],
    element: <FAQPage />,
  },
  [AppRoutes.CONTACT]: {
    path: RoutePath.contact,
    element: <ContactPage />,
  },
  [AppRoutes.PRIVACY]: {
    path: RoutePath["privacy-policy"],
    element: <PrivacyPage />,
  },
  [AppRoutes.ORDER]: {
    path: RoutePath.order,
    element: <OrderPage />,
  },
  [AppRoutes.DELIVERY]: {
    path: RoutePath.delivery,
    element: <DeliveryPage />,
  },
  [AppRoutes.PERSONAL_DATA]: {
    path: RoutePath["personal-data"],
    element: <PersonalDataPage />,
  },
  [AppRoutes.REGISTER]: {
    path: RoutePath.register,
    element: <RegisterPage />,
  },
  [AppRoutes.DETAILS]: {
    path: RoutePath.details,
    element: <CardPage />,
  },
  [AppRoutes.CATEGORIES]: {
    path: RoutePath.categories,
    element: <CategoriesPage />,
  },
  [AppRoutes.COLLECTIONS]: {
    path: RoutePath.collections,
    element: <CollectionsPage />,
  },
  [AppRoutes.FORGOT_PASSWORD]: {
    path: RoutePath["forgot-password"],
    element: <ForgotPasswordPage />,
  },
  [AppRoutes.CONFIRM_PASSWORD]: {
    path: RoutePath["forget-password"],
    element: <ConfirmPasswordPage />,
  },
};
