import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { FormInput } from "@ui-kit/formInput";

export const Forms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: "Пожалуйста, введите email",
        },
        {
          type: "email",
          message: "Введите корректный email",
        },
      ]}
    >
      <FormInput allowClear placeholder={t("login.email")} />
    </Form.Item>
  );
};
