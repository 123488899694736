import { useState, useEffect } from "react";
import { Select } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CaretDownOutlined } from "@ant-design/icons";
import styles from "./filter.module.scss";

const { Option } = Select;

export const FilterSelects = ({ skip, pageSize, pageNum }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [colors, setColors] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [sorting, setSorting] = useState("asc");

  const location = useLocation();
  const url = location.search;
  const params = new URLSearchParams(url);
  const category = decodeURIComponent(params.get("categoryId") || "");

  useEffect(() => {
    const initialColors: any = params.get("color")?.split(",") || [];
    const initialProductTypes: any =
      params.get("product_sex")?.split(",") || [];
    const initialSorting = params.get("sort") || "asc";

    setColors(initialColors);
    setProductTypes(initialProductTypes);
    setSorting(initialSorting);
  }, [category]);

  const handleSelectionChange = () => {
    const newSearchParams = new URLSearchParams();
    if (category) newSearchParams.set("categoryId", category);
    if (colors.length > 0) newSearchParams.set("color", colors.join(","));
    if (productTypes.length > 0)
      newSearchParams.set("product_sex", productTypes.join(","));
    if (sorting) newSearchParams.set("sort", sorting);
    if (skip) newSearchParams.set("skip", skip);
    if (pageSize) newSearchParams.set("limit", pageSize);
    if (pageSize) newSearchParams.set("page", pageNum);

    setSearchParams(newSearchParams);

    navigate(`/categories?${newSearchParams.toString()}`);
  };

  useEffect(() => {
    handleSelectionChange();
  }, [colors, productTypes, sorting]);

  return (
    <div className={styles.selectBlock}>
      <Select
        value={colors}
        mode="multiple"
        suffixIcon={<CaretDownOutlined />}
        placeholder={t("select.color")}
        style={{ width: 200 }}
        onChange={(values) => setColors(values)}
        onBlur={handleSelectionChange}
        onDeselect={handleSelectionChange}
        showSearch={false}
      >
        <Option value="white">{t("select.white")}</Option>
        <Option value="black">{t("select.black")}</Option>
        <Option value="purple">{t("select.purple")}</Option>
        <Option value="yellow">{t("select.yellow")}</Option>
        <Option value="red">{t("select.red")}</Option>
        <Option value="grey">{t("select.grey")}</Option>
      </Select>
      <Select
        value={productTypes}
        mode="multiple"
        showSearch={false}
        suffixIcon={<CaretDownOutlined />}
        placeholder={t("select.product")}
        style={{ width: 200 }}
        onChange={(values) => setProductTypes(values)}
        onBlur={handleSelectionChange}
      >
        <Option value="men">{t("select.man")}</Option>
        <Option value="women">{t("select.women")}</Option>
        <Option value="boy">{t("select.kids")}</Option>
        <Option value="girl">{t("select.girl")}</Option>
        <Option value="none">{t("select.none")}</Option>
      </Select>
      <Select
        value={sorting}
        suffixIcon={<CaretDownOutlined />}
        placeholder="Select sorting method"
        style={{ width: 200 }}
        showSearch={false}
        onChange={(value) => setSorting(value)}
        onBlur={handleSelectionChange}
      >
        <Option value="asc">{t("select.asc")}</Option>
        <Option value="desc">{t("select.desc")}</Option>
      </Select>
    </div>
  );
};
