import { useEffect, useState } from "react";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IClothes } from "@enums/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getCollections, setCount } from "@store/slices";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import { Button } from "antd";
import { RoutePath } from "@router/routePath";
import { useAppSelector } from "@hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { getCollectionsSelector } from "@store/selectors";
import { SEO } from "@utils/SEO";
import { TotalBlock } from "./TotalBlock";
import styles from "./CartLayout.module.scss";

export const CartLayout = () => {
  const { t } = useTranslation();
  const cart = JSON.parse(localStorage.getItem("cart") || "[]");
  const [state, setState] = useState(cart);
  const [sum, setSum] = useState(0);
  const dispatch = useAppDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const collection = useAppSelector(getCollectionsSelector);
  const recCollection = collection[collection.length - 1];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCollections());
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    LocalStorage.setItem(LocalStorageKey.Sum, String(sum));
  }, [sum]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const returnSum = (arr: any[]) => {
    let total = 0;

    if (arr.length) {
      total = arr.reduce((acc, item) => {
        return acc + item.current_price * item.buyQuantity;
      }, 0);
    }

    setSum(total > 0 ? total : 0);
  };

  useEffect(() => {
    returnSum(state);
    dispatch(setCount(cart.length));
  }, []);

  const addToCart = (product: IClothes) => {
    const existingProduct = cart.find(
      (item: IClothes) => item.id === product.id && item.size === product.size
    );

    if (existingProduct) {
      const updatedCart = cart.filter(
        (item: IClothes) =>
          !(item.id === product.id && item.size === product.size)
      );
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setState(updatedCart);
    }
    const res = JSON.parse(localStorage.getItem("cart") || "[]");
    dispatch(setCount(res.length));
    returnSum(res);
  };

  const changeBuyQuantity = (e: any, elem: any) => {
    const newValue = Number(e.target.value);
    const updatedState = state.map((item: any) => {
      if (item.id === elem.id && item.size === elem.size) {
        return {
          ...item,
          buyQuantity: newValue,
        };
      }
      return item;
    });

    setState(updatedState);
    localStorage.setItem("cart", JSON.stringify(updatedState));
    returnSum(updatedState);
  };

  const handleBlur = (e: any, elem: any) => {
    const newValue = Number(e.target.value);
    if (newValue === 0 || newValue < 0) {
      const updatedState = state.filter(
        (item: any) => !(item.id === elem.id && item.size === elem.size)
      );
      setState(updatedState);
      localStorage.setItem("cart", JSON.stringify(updatedState));
      returnSum(updatedState);
    }
  };

  return (
    <div className={styles.pageBlock}>
      <SEO title={t("header.cart")} url="/cart" />

      <div className={styles.mainBlock}>
        <div className={styles.contentBlock}>
          <div className={styles.cardBlock}>
            {state.length ? (
              state.map((item: any) => (
                <div key={`${item.id}${item.size}`} className={styles.card}>
                  <div className={styles.cardContent}>
                    <div>
                      <img src={item.images[0]} alt="" className={styles.img} />
                    </div>
                    <div className={styles.nameBlock}>
                      <div className={styles.name}>{item.name}</div>
                      <div className={styles.id}>ID: {item.id}</div>
                      <div>
                        {screenWidth > 490 && (
                          <>
                            <div className={styles.id}>
                              {`${t("cart.available")}${item.sizeQuantity}`}
                            </div>
                            <div className={styles.id}>
                              {`${t("cart.size")}${item.size}`}
                            </div>
                          </>
                        )}
                        <input
                          type="number"
                          min="1"
                          className={styles.input}
                          defaultValue={item.buyQuantity}
                          onChange={(e) => changeBuyQuantity(e, item)}
                          onBlur={(e) => handleBlur(e, item)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.closeBlock}>
                    <CloseOutlined
                      className={styles.close}
                      onClick={() => addToCart(item)}
                    />
                    <div className={styles.name}>
                      {item.current_price} {t("som")}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <div className={styles.name}>{t("cart.none")}</div>
                {collection.length && (
                  <Button
                    className={styles.btn2}
                    onClick={() =>
                      collection.length &&
                      navigate(
                        `${RoutePath.collections}?collectionId=${recCollection.id}`
                      )
                    }
                  >
                    {t("cart.btn2")} <ArrowRightOutlined />
                  </Button>
                )}
              </div>
            )}
          </div>
          <TotalBlock state={state} sum={sum} btnNone />
        </div>
      </div>
    </div>
  );
};
