export const AuthApi = {
  Login: "/user/auth/login",
  Register: "/user/auth/register",
  Trainers: "/trainers",
  Products: "/product-categories",
  Funders: "/funders",
  SendCode: "/user/auth/verification-code",
  CheckCode: "/user/auth/verify-email",
  Application: "/applications",
  ForgotPassword: "/user/auth/reset-password/link",
  Orders: "/orders",
  ConfirmPassword: "/user/auth/reset-password",
  Logout: "/user/auth/logout",
  Profile: "/user/profile",
  Cafe: "/cafes",
  Hotel: "/hotels",
  KinderGartens: "/kindergartens",
  Mall: "/malls",
  Collections: "/product-collections",
  Contact: "/contact/list",
  InfoPage: "/info-pages",
  Clothes: "/products",
  Social: "/social-networks",
  Museum: "/museums",
  Camp: "/camps",
  LoginByGoogle: "/user/auth/google",
  LoginByFacebook: "/user/auth/facebook",
  LoginByVK: "/user/auth/vk",
  LoginByYandex: "/user/auth/yandex",
  Keywords: "/products/search/keywords",
};
