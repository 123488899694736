import { useEffect, useState } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getClothes } from "@store/slices";
import { useAppSelector } from "@hooks/useAppSelector";
import { getClothesSelector } from "@store/selectors";
import { useTranslation } from "react-i18next";
import { Loader } from "@ui-kit/loader";
import { ProductCardMain } from "@ui-kit/productCard3";
import styles from "./CategoryLayout.module.scss";

export const ClothesList = () => {
  const { t } = useTranslation();
  const clothes = useAppSelector(getClothesSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getClothes({ params: "?sort=desc&limit=12" })).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.cardBlcok}>
      {clothes.length ? (
        clothes.map((item) => <ProductCardMain data={item} key={item.id} />)
      ) : (
        <div className={styles.spin}>
          {loading ? (
            <Loader />
          ) : (
            <span className={styles.emptyDesc}>{t("category.not")}</span>
          )}
        </div>
      )}
    </div>
  );
};
