export enum LocalStorageKey {
  AccessToken = "at",
  RefreshToken = "rt",
  PersonalData = "personal",
  AcceptCookie = "accept_cookie",
  Filial = "filial",
  Lang = "lang",
  Sum = "sum",
}

export enum LocalStorageValues {
  Bishkek = "bishkek",
  JalalAbad = "jalal-abad",
}
