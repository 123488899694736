import { FC, useEffect } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "@hooks/useAppSelector";
import { getCategoriesSelector } from "@store/selectors";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getProducts } from "@store/slices";
import { RoutePath } from "@router/routePath";
import { useTranslation } from "react-i18next";
import styles from "../../Header.module.scss";

interface AcademyBlockProps {
  closeMenuAndNavigate: (path: string) => void;
  toggleMenu: any;
  setShowAcademyBlock: any;
}

export const CategoryBlock: FC<AcademyBlockProps> = ({
  setShowAcademyBlock,
  closeMenuAndNavigate,
  toggleMenu,
}) => {
  const dispatch = useAppDispatch();
  const collections = useAppSelector(getCategoriesSelector);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  const handleAcademyClick = () => {
    setShowAcademyBlock((prev: boolean) => !prev);
  };

  const handleMenuItemClick = (path: any) => {
    closeMenuAndNavigate(path);
    toggleMenu();
  };
  return (
    <motion.div
      className={styles.additionalBlock}
      animate={{
        x: 0,
        backgroundColor: "#181733",
      }}
      exit={{
        x: "100%",
        transition: { duration: 0.2 },
      }}
      initial={{ x: "100%" }} // Starts from right (fully hidden)
      transition={{ ease: "linear", duration: 0.2 }}
    >
      <div className={styles.listItems}>
        <div className={styles.blockBorder3}>
          <div className={styles.arrow} />
          <motion.li
            initial={{
              x: "100%",
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
            }}
            className={styles.academy2}
            onClick={handleAcademyClick}
          >
            {t("header.categories")}{" "}
          </motion.li>{" "}
        </div>
        <div className={styles.scrollBlock}>
          {collections.length &&
            collections.map((item) => (
              <div className={styles.blockBorder2} key={item.id}>
                <motion.li
                  initial={{
                    x: "-100px",
                  }}
                  animate={{
                    x: 0,
                  }}
                  transition={{
                    duration: 0.4,
                  }}
                  className={styles.academy2}
                  onClick={() =>
                    handleMenuItemClick(
                      `${RoutePath.categories}?categoryId=${item.id}`
                    )
                  }
                >
                  <NavLink to={`${RoutePath.categories}?categoryId=${item.id}`}>
                    {item.name}
                  </NavLink>
                </motion.li>{" "}
              </div>
            ))}
        </div>
      </div>
    </motion.div>
  );
};
