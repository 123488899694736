import { useCallback, useEffect } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SignUpType } from "@models/login";
import { AppDispatch } from "@store/index";
import { setEmail, setPassword, signUp } from "@store/slices/barca/barcaSlice";
import { Form, FormInstance } from "antd";
import { useAppSelector } from "@hooks/useAppSelector";
import { getPasswordSelector } from "@store/selectors";
import { useAlert } from "@hooks/useAlert";
import { useTooltip } from "@hooks/useTooltip";
import { useTranslation } from "react-i18next";

type ReturnType = {
  password: string;
  form: FormInstance;
  onFinish: (values: SignUpType) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity<SignUpType>) => void;
};

export const useRegister = (): ReturnType => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const email = Form.useWatch("email", form);
  const passwordValue = Form.useWatch("password", form);
  const password = useAppSelector(getPasswordSelector);
  const { setAlert } = useAlert();
  const { success } = useTooltip();
  const { t } = useTranslation();

  useEffect(() => {
    if (passwordValue) {
      dispatch(setPassword(passwordValue));
    }
  }, [passwordValue]);

  useEffect(() => {
    if (email) {
      dispatch(setEmail(email));
    }
  }, [email]);

  const onFinish = useCallback(
    (values: SignUpType) => {
      const { confirmPassword, ...data } = values;
      dispatch(signUp({ values: data, navigate, setAlert })).then((res) => {
        if (res.payload) {
          success(t("validateSms.register"));
        }
      });
    },
    [dispatch]
  );

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<SignUpType>) => {
      console.log("Failed:", errorInfo);
    },
    []
  );

  return {
    form,
    password,
    onFinish,
    onFinishFailed,
  };
};
