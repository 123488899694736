import { AppRoutes, Routes } from "@enums/routes";

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: Routes.MAIN,
  [AppRoutes.CART]: Routes.CART,
  [AppRoutes.LOGIN]: Routes.LOGIN,
  [AppRoutes.DETAILS]: Routes.DETAILS,
  [AppRoutes.REGISTER]: Routes.REGISTER,
  [AppRoutes.CATEGORIES]: Routes.CATEGORIES,
  [AppRoutes.COLLECTIONS]: Routes.COLLECTIONS,
  [AppRoutes.ORDER]: Routes.ORDER,
  [AppRoutes.FORGOT_PASSWORD]: Routes.FORGOT_PASSWORD,
  [AppRoutes.CONFIRM_PASSWORD]: Routes.CONFIRM_PASSWORD,
  [AppRoutes.PERSONAL_DATA]: Routes.PERSONAL_DATA,
  [AppRoutes.DELIVERY]: Routes.DELIVERY,
  [AppRoutes.PROFILE]: Routes.PROFILE,
  [AppRoutes.FAQ]: Routes.FAQ,
  [AppRoutes.CONTACT]: Routes.CONTACT,
  [AppRoutes.PRIVACY]: Routes.PRIVACY,
};
