import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useEffect } from "react";
import { SEO } from "@utils/SEO";
import { Routes } from "@enums/routes";
import styles from "./Privacy.module.scss";

export const PrivacyLayout = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <SEO titleNew={t("footer.policy")} url={Routes.PRIVACY} />

      <div className={styles.contact}>
        <div className={styles.header}>
          <h1
            className={cn(styles.title, {
              [styles.ru]: i18n.language === "ru",
            })}
          >
            {t("footer.policy")}
          </h1>
        </div>
        <iframe
          src={`files/privacyPolicy${i18n.language.toUpperCase() ?? "RU"}.html`}
          title="Word"
          width="100%"
          height="600px"
          style={{ height: "80vh" }}
        />
      </div>
    </div>
  );
};
